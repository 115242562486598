/* eslint-disable */
import React from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

export default function LandingPage() {

// const { pinftslp } = props;

// console.log(pinftslp)

  const images = [
    "./images/001.jpg",
    "./images/002.jpg",
    "./images/003.jpg",
    "./images/004.jpg",
    "./images/005.jpg",
    "./images/006.jpg",
    "./images/007.jpg",
    "./images/008.jpg",
    "./images/009.jpg",
    "./images/010.jpg",
    "./images/011.jpg",
    "./images/012.jpg",
  ]




  return (<>
    <div style={{ height: "100px" }}></div>
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1050: 4 }}>
      <Masonry columnsCount={4} gutter="10px">
        {images.map((pinft) => (
          <div key={pinft} style={{ backgroundColor: "#eeeeee", paddingBottom: "2px" }}  >
            <img alt="imagetype" src={pinft}
              style={{ padding: "3px", display: "block", width: "100%" }}
            />

          </div>
        ))}
      </Masonry>
    </ResponsiveMasonry>

  </>)

}