/* eslint-disable */
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert'

import Loading from "../Loading/Loading";



export default function CreateItem(props) {
  const [fileUrl, setFileUrl] = useState('');
  const [fileUrlType, setFileUrlType] = useState('');
  const [fileUrl2, setFileUrl2] = useState('');
  const [fileUrlType2, setFileUrlType2] = useState('');
  const [formInput, updateFormInput] = useState({ name: '', tags: '', description: '', price: '1', quantity: '1' });
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const alert = useAlert()

  const fileRef = useRef();
  const fileRef2 = useRef();

  const [busdChecked, setBusdChecked] = useState(false);
  const [secretChecked, setSecretChecked] = useState(false);

  const handleSecret = () => {
    setSecretChecked(!secretChecked);
  };

  const handleBusd = () => {
    setBusdChecked(!busdChecked);
  };



  async function onChange(e) {
    const file = e.target.files[0];
    if (typeof file === "undefined") {
      return
    }
    if (file.type !== "image/gif" && file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png") {
      alert.show("Please choose an image file");
      return;
    }
    if (file.size > 12000000) {
      alert.show("Please use a smaller file");
      return
    }
    setFileUrlType(file.type);
    setLoadingImage(true);
    setLoading(true);
    try {
      const added = await props.client.add(
        file,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )

      const url = `https://pinftagram.infura-ipfs.io/ipfs/${added.path}`
      setFileUrl(url);
      setLoadingImage(false);
      setLoading(false);



    } catch (error) {
      alert.show("error");
      console.log('Error uploading file: ', error)
    }
  }

  async function onChange2(e) {
    const file2 = e.target.files[0];
    if (typeof file2 === "undefined") {
      return
    }

    if (file2.type !== "video/mpg" && file2.type !== "video/quicktime" && file2.type !== "video/mp4") {
      alert.show("Please choose a video file");
      return;
    }

    if (file2.size > 12000000) {
      alert.show("Please use a smaller file");
      return
    }
    setFileUrlType2(file2.type);
    setLoadingAnimation(true);
    setLoading(true);
    try {
      const added2 = await props.client.add(
        file2,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )
      const url2 = `https://pinftagram.ipfs-infura.io/ipfs/${added2.path}`

      setFileUrl2(url2);

      setLoadingAnimation(false);
      setLoading(false);

    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }



  async function createMarket(e) {
    e.preventDefault();

    const { name, tags, description, quantity } = formInput

    if (!name || !description || !fileUrl || !fileUrlType) {
      // alert.show("Please complete all entries");
      alert.show("Please complete all entries");
      return;
    }

    if (quantity > 2000) {
      alert.show("Maximum number of copies is 2000");
      return;
    }
    if (quantity <= 0) {
      alert.show("Number of copies is not acceptable");
      return;
    }


    props.mintMyNFT2(quantity, props.accountAddress, name, tags, description, fileUrl, fileUrlType, fileUrl2, fileUrlType2, secretChecked, busdChecked, navigate);

    // } catch (error) {
    //   console.log('Error uploading file: ', error)
    // }  
  }




  return (
    <>
      <br /><br /><br /><br />
      <div className="_2ndmainsection">

        <div className="mint-container">
            <div className="text-block-10">Mint an NFT</div>

          <div className="mint-image-container">
            <div className="mint-image-holder">


              <div className="mint-image-border-container">
                <div className="mint-image-upload-border">
                  {loadingImage && <p>Loading Image</p>}
                  {loadingImage && <Loading />}
                  {fileUrl && (<img alt="file" className="image-4"
                    style={{ maxHeight: "500px", cursor: "pointer" }} src={fileUrl}
                    onClick={() => fileRef.current.click()} aria-hidden="true" />)}

                  <input
                    ref={fileRef}
                    onChange={onChange}
                    multiple={false}
                    type="file"
                    name="Asset"
                    hidden
                  />

                </div>
                {!fileUrl &&
                  <div className="text-block-23">
                    <br />
                    <button type="button" onClick={() => fileRef.current.click()}>
                      <img src="images/Upload-Arrow-Icon.png" loading="lazy" width="60" alt="" className="image-5" />
                    </button>
                    <br /><br />
                    Click to Upload image
                    <br />
                  </div>

                }
              </div>


            </div>

            {/* :  ( <img src="./images/Add-Icon.png" loading="lazy" width="70" alt="" className="image-4" />)} */}


            <div className="mint-text-container">
              <div className="text-block-17">Title</div>
              <div className="text-block-18">
                <input

                  className="user-handle-input-box"
                  onChange={e => updateFormInput({ ...formInput, name: e.target.value })}
                />
              </div>

              <div className="text-block-17">Description</div>

              <div className="text-block-18">
                <textarea
                  className="user-handle-input-box"
                  onChange={e => updateFormInput({ ...formInput, description: e.target.value })}
                />

              </div>
              <div className="text-block-17">Quantity</div>

              <div className="text-block-18">
                <input
                  defaultValue="1"
                  className="user-handle-input-box"
                  onChange={e => updateFormInput({ ...formInput, quantity: e.target.value })}
                />
              </div>

              <div className="text-block-17">Tags</div>

              <div className="text-block-18">
                <textarea
                  className="user-handle-input-box"
                  onChange={e => updateFormInput({ ...formInput, tags: e.target.value })}
                />
              </div>
              <br />
              <div className="text-block-17">
                Private <input type="checkbox"
                  style={{ marginLeft: "10px" }}
                  checked={secretChecked}
                  onChange={handleSecret}
                />&nbsp;&nbsp;&nbsp;&nbsp;
                Mint with BUSD<input type="checkbox"
                  style={{ marginLeft: "10px" }}
                  checked={busdChecked}
                  onChange={handleBusd}
                />
              </div>

              <br /><br /><br />
              <div className="mint-optional-box">
                {loadingAnimation && <p>Loading Animation</p>}
                {loadingAnimation && <Loading />}
                <div className="mint-optional-upload-image">
                  <button type="button" onClick={() => fileRef2.current.click()}>
                    <img src="images/Add-Icon.png" loading="lazy" width="60" alt="" className="image-4" />
                  </button>
                  <input
                    ref={fileRef2}
                    onChange={onChange2}
                    multiple={false}
                    type="file"
                    name="Asset2"
                    hidden
                  />
                </div>
                <div className="mint-optional-box-container">
                  <div className="mp4-mp3-box">
                    <div className="text-block-20">.mp4, .mp3 file (Optional)</div>

                    {/* <div className="text-block-19">Delete </div> */}
                  </div>

                  <div className="mint-hr" />
                  {/* {  fileUrl2 && (  <img alt="file" className="image-4"   src={fileUrl2} />  ) } */}

                  {fileUrl2 && (<span>File Chosen</span>)}
                  {!fileUrl2 && <div className="text-block-21">No file chosen</div>}
                </div>
              </div>


              <div className="mint-create-box">
                <div  >
                  <button onClick={(e) => createMarket(e)} disabled={!fileUrl} type="button"
                    style={{
                      borderRadius: "50px", fontSize: "18px",
                      backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                    }} >

                    Mint
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}