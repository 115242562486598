/* eslint-disable */
import React from "react";
import { useNavigate } from 'react-router-dom';
// import config from "../../config.json";
import portfolio from "./portfolio.svg";


export const ShowIndiv = (props: any) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/indivtoken/?tokenId=${props.tokenId}`);
    window.location.reload();
  }

  return (
    <button type="button" onClick={handleOnClick} >
      {props.tokenId}
    </button>
  )

}


export const ConnectToGallery = (props: any) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (props.currentAddress !== props.accountAddress) {
      navigate(`/marketplace1/?minter=${props.accountAddress}`);
    } else {
      navigate(`/mytokens`);
    }
    window.location.reload();


  }

  if (props.accountAddress === "0x00") {
    return (<p className="mt-4"> </p>)
  }

  return (
    <button type="button" onClick={handleOnClick}>
      <img
        src={portfolio}
        alt="portfolio-icon"
        style={{ width: "18px", marginLeft: "5px" }} />

    </button>

  )
}


export const ConnectToExplorer = (props: any) => {



  const { accountAddress, handle } = props;
  let AddressLink = '';


  AddressLink = `https://bscscan.com/address/${props.accountAddress}`

  if (accountAddress === "0x0000000000000000000000000000000000000000") {
    return (<p className="mt-4">Zero Address</p>)
  }

  if (accountAddress === "0x00") {
    return (<p className="mt-4"> </p>)
  }

  let displayname = '';
  if (handle !== "" && typeof handle !== "undefined") {
    displayname = handle;
  } else {
    displayname = `${accountAddress.substr(0, 5)}  ...  ${accountAddress.slice(accountAddress.length - 6)}`
  }

  return (

    <a
      href={`${AddressLink}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "black" }}
    >
      {displayname}
    </a>

  )

}

