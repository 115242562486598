/* eslint-disable */
import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import Loading from "../Loading/Loading";
import { useAlert } from 'react-alert'

export default function Profile(props) {

  const [fileUrl, setFileUrl] = useState('');
  const [fileUrlType, setFileUrlType] = useState("image/jpeg");
  const [fileSelected, setFileSelected] = useState(false);

  const [formInput, updateFormInput] = useState({ handle: '', tagline: '' });
  const [loadingImage, setLoadingImage] = useState(false);

  const [loadedProfile, setLoadedProfile] = useState(false)

  const [loading, setLoading] = useState(false);

  const { myHandle } = props;


  const { handleimageuri, handlename, handletagline } = myHandle;
  // const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');
   
  const navigate = useNavigate();
  const alert = useAlert()

  useEffect(() => {
    async function setupProfile() {
      setLoadedProfile(false);

      setFileUrl(handleimageuri);
      updateFormInput({ handle: handlename, tagline: handletagline });

      setLoadedProfile(true);
    }
    setupProfile()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function openPortfolio() {
    navigate(`/mytokens`);
  }

  async function onChange(e) {
    const file = e.target.files[0];
    if (typeof file === "undefined") {
      return
    }
    if (file.size > 2000000) {
      alert.show("please use a smaller file");
      return
    }

    if (file.type !== "image/gif" && file.type !== "image/jpg" && file.type !== "image/jpeg" && file.type !== "image/png") {
      alert.show("Please choose an image file");
      return;
    }

    setFileUrlType(file.type);
    setFileSelected(true);
    setLoadingImage(true);
    setLoading(true);
    try {
      const added = await props.client.add(
        file,
        {
          progress: (prog) => console.log(`received: ${prog}`)
        }
      )
      const url = `https://pinftagram.infura-ipfs.io/ipfs/${added.path}`
       

      setFileUrl(url);
      setLoadingImage(false);
      setLoading(false);
    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }

  async function createUser(e) {
    e.preventDefault();

    const { handle, tagline } = formInput

    if (!handle || !tagline || !fileUrl) {
      alert.show("Please complete all entries");
      return;
    }
  

    props.updateProfile(handle, tagline, fileUrl);

  }
  const { handle } = formInput


  if (!loadedProfile) {
    return <div>.</div>
  }

  return (

    <div className="_2ndmainsection">
      {handle === null && <em>null</em>}


      <div >
        <button onClick={(e) => openPortfolio(e)} type="button"
          style={{
            borderRadius: "50px", fontSize: "18px",
            backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
          }} >

          Portfolio
        </button>
        <div className="user-profile-image-holder">
          <div className="text-block-10">User Profile</div>

          {loadingImage && <p>Loading Image</p>}
          {loadingImage && <Loading />}


          {fileUrl ? (<img alt="file" className="image-3" width={fileSelected ? "350" : "100"} src={fileUrl} />)
            : (<img src="./images/Profile-Blank.png" loading="lazy" width="125" alt="" className="image-3" />)}

          <span className="hiddenFileInput">
            <input className="text-block-12" type="file" name="Asset" onChange={onChange} />
          </span>

        </div>
        <div className="user-profile-input-box">
          <div className="profile-handle-box">
            <div className="text-block-15">Handle</div>
            <div  >
              <input
                className="user-handle-input-box"
                onChange={e => updateFormInput({ ...formInput, handle: e.target.value })}
                defaultValue={formInput.handle}
              /></div>
          </div>
          <div className="profile-tagline-box">
            <div className="text-block-14">Tagline</div>
            <div  >
              <textarea
                className="user-handle-input-box"
                onChange={e => updateFormInput({ ...formInput, tagline: e.target.value })}
                defaultValue={formInput.tagline}
              />
            </div>
          </div>
          <div >
            <button onClick={(e) => createUser(e)} type="button"
              style={{
                borderRadius: "50px", fontSize: "18px",
                backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
              }} >

              {handle === '' ? "Create" : "Update"}
            </button>


          </div>


        </div>
      </div>
      <br /><br /><br /> <br />
    </div>

  )
}