/* eslint-disable */
import React from 'react';
import { useEffect, useState, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, useLocation, Link } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { useAlert } from 'react-alert'
import { Buffer } from "buffer";
import ConnectAccount from "./components/Account/ConnectAccount";
// import DisplayPane from "./components/displayPane/DisplayPane";
//import background from "./assets/images/background.jpg";
import pinftagram_logo from "./assets/images/pinftagramlogo.png";
import { Layout } from "antd";
import "./App.css";
import "antd/dist/antd.css";
import ChainSelector from "./components/ChainSelector";
import { useWeb3React } from "@web3-react/core";
import ReactLoading from "react-loading";
import { handelRightClick } from "./AppUtility";
import { ethers } from 'ethers';
// ,  providers
// import { useAlert } from 'react-alert';

import axios from 'axios';

import { create as ipfsHttpClient } from 'ipfs-http-client'
import InputDataDecoder from "ethereum-input-data-decoder";
import contractsAddress from "./contracts/contract-address.json";
import tokensAddress from "./contracts/token-address.json";
import config from "./config.json";
import Pinfts88 from "./contracts/Pinfts88.json";
import Pinftagram from "./contracts/Pinftagram.json";
import Busd from "./contracts/Busd.json";

// import "./App.css";
import "./index.css";
import './css/normalize.css';
import './css/webflow.css';
import './css/pinftagram.webflow.css';

import CreateItem from "./pages/CreateItem";
import AccountDetails from "./pages/AccountDetails";
import MainPinfts from "./pages/MainPinfts/index";
import IndivToken from "./pages/IndivToken/index";
import Profile from "./pages/Profile";
import Activity from "./pages/Activity";
import LandingPage from "./pages/LandingPage";
//import Navbar from "./pages/Navbar";
// // import ScratchGallery from "./ScratchGallery/ScratchGallery";


export interface IApplicationProps { }

const projectId = '23K46DBU5HjoRSqm5KkDpQj933b'
const projectSecret = '9ab9b1f9d24de4a8f6f443bc07b14adb'
const authorization = "Basic " + btoa(projectId + ":" + projectSecret);

// // try {
// //   console.log(process.env )
// // } catch (e) {
// //   console.log(e)
// // }

const client = ipfsHttpClient({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization
  }
})
const pinftsAddress = contractsAddress.Pinfts88;

const { Header } = Layout; //, Footer
const mobile: boolean = false;
const styles = {
  layout: {

    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
    overflow: "auto",
    fontFamily: "Sora, sans-serif"
  },
  header: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px",
    paddingTop: "0px",
    height: "130px"
  },
  headerRight: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingRight: "10px",
    fontSize: "15px",
    fontWeight: "600"
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    color: "#ffffff",
    marginTop: "100px",
    padding: "10px",
    overflow: "auto"
  },
  footer: {
    position: "fixed",
    textAlign: "center",
    width: "100%",
    bottom: "0",
    color: "white",
    backgroundColor: "transparent"
  },
  ul_menu: {
    listStyleType: "none",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    backgroundColor: "white",
    paddingTop: mobile ? "0px" : "0px"
  },
  li_menu: {
    float: "right"
  },
  lia_menu: {
    display: "block",
    color: "black",
    textAlign: "center",
    padding: "0px",
    textDecoration: "none",
    cursor: "pointer",
    width: "120px",
    height: "40px",
    marginTop: "0px",
    fontSize: "20px"
  }

} as const;


const Application: React.FunctionComponent<IApplicationProps> = (props) => {
  if (!window.Buffer) window.Buffer = Buffer;

  // const location = useLocation();

  // console.log('hash', location.hash);
  // console.log('pathname', location.pathname);
  // console.log('search', location.search);

  const [accountAddress, setAccountAddress] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [pinftsContract, setPinftsContract] = useState<any>(null);

  const [pinftagramAddress] = useState(tokensAddress.Pinftagram);
  const [busdAddress, setBusdAddress] = useState("");

  const [pinftagramContract, setPinftagramContract] = useState<any>(null);
  const [busdContract, setBusdContract] = useState<any>(null);

  const [pinftTaxRate, setPinftTaxRate] = useState(0);
  const [busdPinftRate, setBusdPinftRate] = useState(0);

  const [pinfts, setPinfts] = useState<any>(null);

  const [treasuryReceiver, setTreasuryReceiver] = useState('')

  const [listings, setListings] = useState<any>(null);

  const [myListings, setMyListings] = useState<any>(null);

  const [offers, setOffers] = useState<any>(null);

  const [myOffers, setMyOffers] = useState<any>(null);

  const [offers8, setOffers8] = useState<any>(null);

  const [handles, setHandles] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const [nameIsUsed, setNameIsUsed] = useState(false);

  const [provider1, setProvider1] = useState<any>(null);
  const [httpUrl] = useState(config.httpurl);

  const [isAdmin, setIsAdmin] = useState(false);

  const [myHandle, setMyHandle] = useState({ creator: '', handlename: '', handletagline: '', handleimageuri: '' });
  const [selectedHandle, setSelectedHandle] = useState({ creator: '', handlename: '', handletagline: '', handleimageuri: '' });


  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedId, setSelectedId] = useState(0);


  // const [lastMintTime, setLastMintTime] = useState(0)

  const [mintingPrice, setMintingPrice] = useState(1)
   

  const [loaded, setLoaded] = useState(false)
  const [loadingData, setLoadingData] = useState(false)

  // const alert = useAlert()

  // const [txs, setTxs] = useState([]);

  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const [bscUrl1, setBscUrl1] = useState("");

  const alert = useAlert()

  // console.log(accountAddress, accountBalance, pinftsContract, busdAddress, pinftagramContract,
  //   busdContract, pinftTaxRate, busdPinftRate,
  //   handles, provider1, httpUrl, isAdmin, myHandle, mintingPrice, bscUrl1)

  const getPinfts = async (endpoint: string, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    await setPinfts(response.data)
    return response.data
  }
  const getOffers = async (endpoint: string, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  }

  const getListings = async (endpoint: string, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    await setListings(response.data)
    return response.data
  }



  const getHandles = async (endpoint: string, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  }

  const gethandle = async (endpoint: string, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })


    return response.data
  }

  const getTrail = async (endpoint: string, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data
  }

  // const handleTransferSingle = (operator:string, from:string, to: string, id:string, amount:string) => {
  //   let type:string;

  //   if (from === ethers.constants.AddressZero) {
  //     type = "mint";
  //   } else {
  //     type = "transfer";
  //   }

  //   setTxs((prev) => [
  //     {
  //       type,
  //       from,
  //       to,
  //       id: id.toString(),
  //       amount: amount.toString()
  //     },
  //     ...prev
  //   ]);
  // };

  const { account, provider } = useWeb3React();

  useEffect(() => {
    const fetchAccount = async () => {

      if (typeof (account) !== 'undefined' && typeof (provider) !== 'undefined') {
        // setLoadingData(true);
        // console.log(loadingData)
        setLoaded(false);

        setProvider1(provider);


        const selectedAddress = account.toLowerCase();
        await setAccountAddress(selectedAddress);

        const balance = await provider.getBalance(selectedAddress);
        const accountbalance = ethers.utils.formatEther(balance);
        setAccountBalance(accountbalance);

        const pinftsContract2: any = await new ethers.Contract(pinftsAddress, Pinfts88.abi, provider);
        await setPinftsContract(pinftsContract2);

        const busdaddress = await pinftsContract2.busdAddress();
        await setBusdAddress(busdaddress);

        const busdpinftrate = await pinftsContract2.busdPinftRate();
        await setBusdPinftRate(busdpinftrate.toString());

        const pinfttaxrate = await pinftsContract2.pinftTaxRate();
        await setPinftTaxRate(pinfttaxrate.toString());

        const handles4 = await fetchHandles();
        await setHandles(handles4)


        const myhandles4 = await fetchMyHandle(selectedAddress);

        if (myhandles4 !== null) {

          await setMyHandle(myhandles4)
        }

        const treasuryreceiver = await pinftsContract2.treasuryReceiver();
        setTreasuryReceiver(treasuryreceiver);



        const adminaddress = await pinftsContract2.owner();
        //     console.log(adminaddress)
        // console.log(adminaddress.toLowerCase(), selectedAddress.toLowerCase())
        const isadmin = (adminaddress.toLowerCase() === selectedAddress.toLowerCase())
        await setIsAdmin(isadmin);

        // const isfeeexmpt = await pinftsContract2.isFeeExempt('0xFA92032a4fa4F1659974F6f20d42cC40A9ff8f94');
        // console.log(isfeeexmpt)
      


        const mintingprice = await pinftsContract2.mintingPrice();
        // console.log(mintingprice.toString());
        await setMintingPrice(mintingprice.toString());



        if (isadmin) {
          // const lastblock = await props1.library.getBlockNumber();



          // mint - operator, address(0), account, id, amount
          // transfer -  operator, from, to, id, amount
          //  const filterFrom = pinftsContract2.filters.TransferSingle(null, null, null);
          // // block
          //      const items = await pinftsContract2.queryFilter(filterFrom, 19201070, 20501070);
          //      items.forEach((item) => {
          //        handleTransferSingle(
          //          item.args[0],
          //          item.args[1],
          //          item.args[2],
          //          item.args[3],
          //          item.args[4]
          //        );
          //    //    console.log(item);
          //        return true;
          //      });


          // // fetchOldEvents();
          // contract.on("TransferSingle", handleTransferSingle);
          // return () => {
          //   contract.removeAllListeners("TransferSingle");
          //   setTxs([]);
          // };
        }

        const pinftagramContract2: any = new ethers.Contract(pinftagramAddress, Pinftagram.abi, provider);
        await setPinftagramContract(pinftagramContract2);

      

//  const signer1 = await provider.getSigner();
//  pinftagramContract2.connect(signer1).transfer("0xe002817e4199D84C2060c4936D8980915bF45945","10000000000000000000")
// console.log("gets here")
//   const balancemr = await pinftagramContract2.balanceOf("0xe002817e4199D84C2060c4936D8980915bF45945");
//  console.log(Number(balancemr))

        const busdContract2 = new ethers.Contract(busdaddress, Busd.abi, provider);

        // console.log(pinftsContract2)
        //        const checkpinft = await pinftsContract2.allPinfts("215");
        //          console.log(checkpinft);

        await setBusdContract(busdContract2);

        await setBscUrl1(`https://bscscan.com/token/${pinftagramAddress}`)


        setLoaded(true);
      }

      //  await  setMintBtnTimer();

    }

    fetchAccount();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider])

  const fetchPinfts = async (griddisplaytype: string, selAddress: string, ownedminted: string, querytext: string) => {


    await setLoading(true);

    const pinfts2 = []


    const selhandle = await gethandle(`${config.apiUrl}/gethandle?creator=${selAddress}`, accountAddress)

    if (selhandle !== null) {
      await setSelectedHandle(selhandle);
    }
    let ownedbyarray = ""
    if (griddisplaytype !== "marketplace" && ownedminted !== "minted") {

      const pinftsOfCounter = await pinftsContract.pinftsOfCounter(selAddress);
      if (pinftsOfCounter > 0) {
        const idxarray = Array.from({ length: pinftsOfCounter }, (_, i) => i + 1)

        await Promise.all(idxarray.map(async i => {

          const pinftsOf = await pinftsContract.pinftsOf(selAddress, i - 1);
          ownedbyarray = `${ownedbyarray},${pinftsOf.toString()}`

        }
        ))
      } else {
        ownedbyarray = ""
      }

    }

    const pinftsPage =
      ((griddisplaytype === "marketplace") ?
        ((selAddress === accountAddress) ?
          await getPinfts(`${config.apiUrl}/pinftindex?offset=0`, accountAddress) :
          await getPinfts(`${config.apiUrl}/pinftindex?offset=0&minted=${selAddress}`, accountAddress))
        :
        ((ownedminted === "minted") ?
          await getPinfts(`${config.apiUrl}/pinftindex?offset=0&minted=${selAddress}`, accountAddress) :
          await getPinfts(`${config.apiUrl}/pinftindex?offset=0&owned=${ownedbyarray}`, accountAddress))
      );

    const pinftsCount1 = await pinftsPage.length - 1;

    for (let i = 0; i <= Number(pinftsCount1); i++) {
      if (((griddisplaytype === "portfolio" && (ownedminted === "owned")) || !pinftsPage[i].secret) && (isAdmin || !pinftsPage[i].blocked)) {
        if (querytext === "" || querytext === null || querytext.length < 4) {
          pinfts2.push(pinftsPage[i]);
        } else if (pinftsPage[i].tokenname.toLowerCase().includes(querytext.toLowerCase()) ||
          pinftsPage[i].description.toLowerCase().includes(querytext.toLowerCase()) ||
          pinftsPage[i].tags.toLowerCase().includes(querytext.toLowerCase())

        ) {
          pinfts2.push(pinftsPage[i]);
        }
      }


    }

    // let stringoutput = ""
    //  console.log(pinfts2);
    //   pinfts2.forEach((pinft1) => {

    //     const tokenid = pinft1.tokenid.toString();
    //     const name = pinft1.tokenname;
    //     const tags = pinft1.tags;
    //     const description = pinft1.description;
    //     const quantity = pinft1.quantity.toString();
    //     const tokenuri = pinft1.tokenuri;
    //     const price = pinft1.price.toString();
    //     const pinftorbusd = "0";

    //     stringoutput = `${stringoutput}\n${tokenid},${name},${tags},${description},${quantity},${tokenuri},${price},${pinftorbusd}`

    //   })
    //   console.log(stringoutput)

    await setPinfts(pinfts2);
    await setLoading(false);
    return pinfts2;


  }


  const fetchListings = async (fetchtype: string, selectedsearch: string) => {


    await setLoading(true);

    let listings2 = [];
    if (fetchtype === "token") {
      listings2 = await getListings(`${config.apiUrl}/listingindex?tokenid=${selectedsearch.toString()}`, accountAddress)
    }
    if (fetchtype === "address") {
      listings2 = await getListings(`${config.apiUrl}/listingindex?selleraddress=${selectedsearch}`, accountAddress)
    }

    const listings3 = await Promise.all(listings2.filter((item: { active: any; }) => item.active).map(async (i: { selleraddress: string; id: any; unitprice: any; quantity: any; tokenid: any; active: any; posttime: any; }) => {

      const handlemintedby: any = handles.filter((item: { creator: string; }) => item.creator.toLowerCase() === i.selleraddress.toLowerCase());
      const item = {
        id: i.id,
        unitprice: (i.unitprice * 10 ** -18).toPrecision(2),
        quantity: i.quantity,
        tokenid: Number(i.tokenid),
        selleraddress: i.selleraddress,
        handlemintedby: handlemintedby.length === 1 ?
          handlemintedby[0].handlename : "",
        active: i.active,
        posttime: i.posttime
      }
      return item
    }))


    await setListings(listings3);

    await setMyListings(listings3.filter(item => item.selleraddress.toLowerCase() === accountAddress.toLowerCase()));

    await setLoading(false);
  }

  const fetchOffers = async (fetchtype: string, selectedsearch: string) => {


    await setLoading(true);

    let offers2 = [];
    if (fetchtype === "token") {
      offers2 = await getOffers(`${config.apiUrl}/offerindex?tokenid=${selectedsearch.toString()}`, accountAddress)
    }
    if (fetchtype === "buyeraddress") {
      offers2 = await getOffers(`${config.apiUrl}/offerindex?buyeraddress=${selectedsearch}`, accountAddress)
    }
    if (fetchtype === "selleraddress") {
      offers2 = await getOffers(`${config.apiUrl}/offerindex?selleraddress=${selectedsearch}`, accountAddress)
    }


    const offers3 = await Promise.all(offers2.filter((item: { active: any; }) => item.active).map(async (i: { selleraddress: string; id: any; unitprice: any; quantity: any; tokenId: any; buyeraddress: any; active: any; postTime: any; }) => {

      const handlemintedBy = handles.filter((item: { creator: string; }) => item.creator.toLowerCase() === i.selleraddress.toLowerCase());

      const item = {
        id: i.id,
        unitprice: Math.round(i.unitprice * 10 ** -18),
        quantity: i.quantity,
        tokenId: Number(i.tokenId),
        buyeraddress: i.buyeraddress,
        selleraddress: i.selleraddress,
        handlemintedBy: handlemintedBy.length === 1 ?
          handlemintedBy[0].handlename : "",
        active: i.active,
        postTime: i.postTime
      }
      return item
    }))

    if (fetchtype === "selleraddress") {
      await setOffers8(offers3);
    } else {

      await setOffers(offers2);

      await setMyOffers(offers3.filter(item => item.buyeraddress.toLowerCase() === accountAddress.toLowerCase()));

    }

    await setLoading(false);
  }






  const fetchHandles = async () => {

    await setLoading(true);
    const handles2 = await getHandles(`${config.apiUrl}/fetchhandles`, accountAddress)


    setHandles(handles2);

    await setLoading(false);
    return handles2;
  }


  const fetchMyHandle = async (selectedAddress: string) => {

    await setLoading(true);
    const handles1 = await gethandle(`${config.apiUrl}/gethandle?creator=${selectedAddress}`, accountAddress)

    await setLoading(false);
    return handles1;
  }


  // const setMintBtnTimer = () => {
  //   const mintBtn = document.getElementById("mintBtn");
  //   if (mintBtn !== undefined && mintBtn !== null) {

  //     const lastminttime = localStorage.getItem(accountAddress);

  //     if (lastminttime === undefined || lastminttime === null) {
  //       mintBtn.innerHTML = "Mint My Pinft"
  //     } else {
  //     checkIfCanMint(parseInt(lastminttime));
  //     }

  //     setLastMintTime(Number(lastminttime));
  //   }
  // };

  // const checkIfCanMint = (lastminttime) => {
  //   const mintBtn = document.getElementById("mintBtn");
  //   const timeGap = 300000; // 5min in milliseconds
  //   const countDownTime = lastminttime + timeGap;
  //   const interval = setInterval(() => {
  //     const now = new Date().getTime();
  //     const diff = countDownTime - now;
  //     if (diff < 0) {
  //       mintBtn.removeAttribute("disabled");
  //       mintBtn.innerHTML = "Mint My Pinft";
  //       localStorage.removeItem( accountAddress);
  //       clearInterval(interval);
  //     } else {
  //       const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  //       const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  //       mintBtn.setAttribute("disabled", 'true');
  //       mintBtn.innerHTML = `Next mint in ${minutes}m ${seconds}s`;
  //     }
  //   }, 1000);
  // };

  const updateProfile = async (handle: string, tagline: string, fileUrl: string) => {

    await setLoading(true);
    try {

      if (typeof myHandle.creator !== 'undefined' && myHandle.creator !== '') {
        messageProcess("Updating Handle");
        await axios({
          url: `${config.apiUrl}/updatehandle`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accountAddress}`,
            contentType: false,
            processData: false,
          },
          data: {
            creator: accountAddress,
            handlename: handle,
            handletagline: tagline,
            handleimageuri: fileUrl
          },
        });
      } else {
        messageProcess("Creating Handle");
        await axios({
          url: `${config.apiUrl}/inserthandle`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accountAddress}`,
            contentType: false,
            processData: false,
          },
          data: {
            creator: accountAddress,
            handlename: handle,
            handletagline: tagline,
            handleimageuri: fileUrl
          },
        });


      }


      await setMyHandle({ "creator": accountAddress.toLowerCase(), "handlename": handle, "handletagline": tagline, "handleimageuri": fileUrl })


      messageProcess("Handle Update Complete");

      await setLoading(false);

    } catch (err) {

      alert.show("Failed update Handle");
      await setLoading(false);

    }
  }



  const fetchIndividual = async (selecteditemid: string, history2: any) => {
    await setLoading(true);

    const selecteditem = await pinftsContract.allPinfts(selecteditemid.toString());
    selectIndividual(selecteditem, history2);

    await setLoading(false);
  }

  const selectIndividual = async (selecteditem: any, history2: any) => {

   
    await setSelectedItem(selecteditem);
    await setSelectedId(selecteditem.tokenid)

    history2(`/indivtoken?tokenId=${selecteditem.tokenid}`);

    // console.log(window.location.href)


  }


  const mintMyNFT2 = async (quantity: number, nfthandle: string, name: string, tags: string, description: string, fileUrl: string, fileUrlType: string, fileUrl2: string, fileUrlType2: string, secretflag: string, busdflag: boolean, history2: any) => {



    await setLoading(true);
    messageProcess("Minting");
    try {

      const nameIsUsed1 = await pinftsContract.tokenNameExists(name);
      await setNameIsUsed(nameIsUsed1);
      if (nameIsUsed1) {
        alert.show("name already exists");
        setLoading(false);
        //   window.location.reload();
        return;
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      // window.location.reload();
      return;
    }

    // secret
    // let tags1 = tags;
    // if (secretflag ) {
    //   tags1 = `secret ${tags}`;
    // }  

    const tokenObject = JSON.stringify({
      "name": name,
      "description": description,
      "image": fileUrl,
      "animation_url": fileUrl2,
      "animation_type": fileUrlType2,
      "attributes": [
        {
          "trait_type": "Quantity",
          "value": quantity
        },
        {
          "trait_type": "Creator",
          "value": nfthandle
        },
        {
          "trait_type": "Tags",
          "value": tags
        },
        {
          "display_type": "date",
          "trait_type": "minted",
          "value": Math.floor(Date.now() / 1000)
        }
      ]

    });

   //  console.log(tokenObject);



    const cid = await client.add(tokenObject);

    const tokenURI = `https://pinftagram.infura-ipfs.io/ipfs/${cid.path}`;

    // const price = ethers.utils.parseEther(tokenPrice.toString());
    const price = 0;
    const signer1 = await provider1.getSigner();

    let receipt1p;
    let txitem1p;

    let pinftorbusd = "0";
    if (busdflag) {
      pinftorbusd = "1";
      const balanceme = await pinftagramContract.balanceOf(accountAddress);
      if (Number(balanceme) < 1) {
        alert.show("Please top up your busd token balance");
        await setLoading(false);
        return;
      }

      const mintingfee1 = quantity * Number(mintingPrice / 1000);
      const mintingfee = ethers.utils.parseUnits(mintingfee1.toString(), 'ether')
      messageProcess(`Approving minting fee ${mintingfee1}`);

      txitem1p = await busdContract.connect(signer1).approve(pinftsContract.address, mintingfee.toString());
      receipt1p = await txitem1p.wait();
    } else {

      pinftorbusd = "0";
      const balanceme = await pinftagramContract.balanceOf(accountAddress);

      if (Number(balanceme) < 1) {
        alert.show("Please top up your pinftagram token balance");
        await setLoading(false);
        return;
      }
      const mintingfee1 = quantity * Number(mintingPrice / 1000);
      const mintingfee = ethers.utils.parseUnits(mintingfee1.toString(), 'ether')

      messageProcess(`Approving minting fee ${mintingfee1}`);

      txitem1p = await pinftagramContract.connect(signer1).approve(pinftsContract.address, mintingfee.toString());
      receipt1p = await txitem1p.wait();
    }


    if (receipt1p.status === 0) {
      console.log("Transaction failed");
      messageProcess(`Approving minting fee failed.`);
      throw new Error("Transaction failed");
    }

    try {
      messageProcess("Minting");


      //  console.log(name, tags1, description, quantity.toString(), tokenURI, price.toString())
      const txitem1 = await pinftsContract.connect(signer1).mintPinft(name, tags, description, quantity.toString(), tokenURI, price.toString(), pinftorbusd, { from: accountAddress })
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {

        await setLoading(false);
        alert.show("Minting failed");
        throw new Error("Transaction failed");

      } else {
        const pinftid = receipt1.events[0].args[3].toString();
        const pinftretval = await pinftsContract.allPinfts(pinftid);


        await axios({
          url: `${config.apiUrl}/pinftcreate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accountAddress}`,
            contentType: false,
            processData: false,
          },
          data: {
            pinftid,
            tokenid: pinftretval.tokenid.toString(),
            tokenname: pinftretval.tokenname,
            tokenuri: pinftretval.tokenuri,
            tags: pinftretval.tags,
            description: pinftretval.description,
            mintedby: pinftretval.mintedby.toLowerCase(),
            price: pinftretval.price.toString(),
            quantity: pinftretval.quantity.toString(),
            numberoftransfers: pinftretval.numberoftransfers.toString(),
            posttime: pinftretval.posttime.toString(),
            saletime: pinftretval.saletime.toString(),
            secret: secretflag
          },
        });

        localStorage.setItem(accountAddress, new Date().getTime().toString());
        alert.show("Minting Successful. Refreshing");
        history2(`/mytokens`);

        // window.location.reload();

        await setLoading(false);

      }
    } catch (e) {
      alert.show(e);
      await setLoading(false);
      window.location.reload();
    }


  }


  const sendTip = async (minteraddress: string, tipprice: string) => {
    console.log(minteraddress, tipprice.toString())
    await setLoading(true);
    messageProcess("About to Tip");



    const signer1 = await provider1.getSigner();
    try {

      messageProcess("Approving Tip");


      const checkbalance = await pinftagramContract.balanceOf(accountAddress);
      console.log(minteraddress, tipprice.toString())
      if (checkbalance < tipprice) {
        alert.show("Insufficient Balance")
        await setLoading(false);
        return false;
      }

      // const txitem1p = await pinftagramContract.connect(signer1).approve(pinftsContract.address ,buyingfee.toString());
      // const receipt1p = await txitem1p.wait();

      // if (receipt1p.status === 0) {
      //  console.log("Transaction failed");
      //  throw new Error("Transaction failed");
      // }

      // const userapp = await pinftagramContract.allowance(accountAddress,pinftsContract.address )
      // console.log(userapp.toString())
      // console.log(tokenId  ,price.toString(), quantity, seller);

      // console.log(pinftsContract)
      // const checkqty = await  pinftsContract._tokenMintQty(tokenId  )
      // console.log(checkqty)


      messageProcess("Tipping in process.");

      const txitem1 = await pinftagramContract.connect(signer1).transfer(minteraddress, tipprice.toString())

      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        alert.show("Tip transfer failed");
        await setLoading(false);
        return false;

      }
      messageProcess("Tip transfer successful.");

      await setLoading(false);
      return true;

    } catch (e) {


      await setLoading(false);
      return false;
    }


  }

  const createOffer = async (tokenid: string, price: string, quantity: string) => {
    await setLoading(true);

    try {

      messageProcess("Creating Offer 1 of 2");
      await axios({
        url: `${config.apiUrl}/offercreate`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          tokenid,
          unitprice: price.toString(),
          quantity: quantity.toString(),
          buyeraddress: accountAddress,
          selleraddress: "0x00",
          active: true,
          posttime: Math.floor(Date.now() / 1000)
        },
      });



      window.location.reload();


    } catch (e) {
      console.log(e)
      await setLoading(false);
      window.location.reload();
    }
    await setLoading(false);
  }


  const agreeOffer = async (listingid: string) => {
    await setLoading(true);

    try {

      messageProcess("Agree to Offer");

      await axios({
        url: `${config.apiUrl}/offeragree`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          id: listingid,
          selleraddress: accountAddress,
          posttime: Math.floor(Date.now() / 1000)
        },
      });

      await setLoading(false);
      window.location.reload();


    } catch (e) {
      await setLoading(false);
      window.location.reload();
    }

  }

  const cancelOffer = async (offerid: string) => {

    await setLoading(true);

    try {
      messageProcess("Cancelling Offer");

      await axios({
        url: `${config.apiUrl}/offerdestroy`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          id: offerid
        },
      });

      messageProcess("Offer Reset");

      await setLoading(false);
      window.location.reload();


    } catch (e) {
      console.log(e);

      await setLoading(false);
      window.location.reload();

    }

  }

  const acceptbuyoffer = async (offerid: string, tokenId: string, price: string, quantity: number, buyer: string, seller: string, busdflag: boolean) => {
    await setLoading(true);
    console.log(buyer);
    try {

      messageProcess("Buying");

      const retval = await buyPinft(tokenId, price, quantity, seller, busdflag);

      if (retval) {
        cancelOffer(offerid);
      }
    } catch (e) {

      await setLoading(false);
      window.location.reload();
    }

    await setLoading(false);

  }

  const cancelagreeOffer = async (offerid: string) => {
    setLoading(true);

    try {

      messageProcess("Rejecting Offer");

      await axios({
        url: `${config.apiUrl}/offercancelagree`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          id: offerid
        },
      });

      await setLoading(false);
      window.location.reload();


    } catch (e) {

      await setLoading(false);
      window.location.reload();

    }
    await setLoading(false);
  }


  const createListing = async (tokenid: string, price: string, quantity: string) => {

    await setLoading(true);

    // uint256 senderbalance = IERC1155(pinfts88Address).balanceOf(msg.sender, _tokenId);
    // require(senderbalance >= _quantity, "Insufficient balance");

    // require(_tokenId > 0, "invalid token id");
    try {
      messageProcess("Creating Listing Step 1 of 1");
      await axios({
        url: `${config.apiUrl}/listingcreate`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          tokenid,
          unitprice: price.toString(),
          quantity: quantity.toString(),
          selleraddress: accountAddress,
          active: true,
          posttime: Math.floor(Date.now() / 1000)
        },
      });

      setLoading(false);

      window.location.reload();

    } catch (e) {

      await setLoading(false);

      window.location.reload();
    }
    await setLoading(false);
  }

  const cancelListing = async (listingid: string) => {
    await setLoading(true);

    try {
      messageProcess("Resetting Listing  ");
      await axios({
        url: `${config.apiUrl}/listingdestroy`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          id: listingid
        },
      });

      await setLoading(false);
      window.location.reload();
    } catch (e) {
      await setLoading(false);
      window.location.reload();
    }
    await setLoading(false);
  }


  const acceptListing = async (listingid: string, tokenId: string, price: string, quantity: number, seller: string, busdflag: boolean) => {
    await setLoading(true);
    try {

      messageProcess("Buying");

      const retval = await buyPinft(tokenId, price, quantity, seller, busdflag);

      if (retval) {
        cancelListing(listingid);
      }
    } catch (e) {
      console.log(e);
      await setLoading(false);
      window.location.reload();
    }
    await setLoading(false);
  }


  const reportNFTDetails = async (tokenid: string) => {
    await setLoading(true);

    try {
      messageProcess("Reporting Token Details");


      await axios({
        url: `${config.apiUrl}/pinftreport`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          tokenid: tokenid.toString(),
          reporteraddress: accountAddress
        },
      });

      messageProcess("Reported Token Details");

      await setLoading(false);

      window.location.reload();



    } catch (e) {

      messageProcess("Failed to report token details");

      await setLoading(false);

      window.location.reload();

    }
    await setLoading(false);
  };


  const blockNFTDetails = async (tokenid: string, blockedflag: string) => {
    await setLoading(true);

    try {
      messageProcess("Blocking Token Details");

      await axios({
        url: `${config.apiUrl}/pinftblock`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          tokenid: tokenid.toString(),
          blocked: !blockedflag,
          blockeraddress: accountAddress
        },
      });

      messageProcess("Blocked Token Details");

      setLoading(false);

      window.location.reload();

    } catch (e) {

      messageProcess("Failed to block token details");

      setLoading(false);

      window.location.reload();

    }
    await setLoading(false);
  };


  const secretNFTDetails = async (tokenid: string, secretflag: string) => {
    await setLoading(true);

    try {
      messageProcess("Hiding Token Details");

      await axios({
        url: `${config.apiUrl}/pinftsecret`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accountAddress}`,
          contentType: false,
          processData: false,
        },
        data: {
          tokenid: tokenid.toString(),
          secret: !secretflag,
          reporteraddress: accountAddress
        },
      });

      messageProcess("Hidden Token Details");

      await setLoading(false);

      window.location.reload();

    } catch (e) {

      messageProcess("Failed to hide token details");

      await setLoading(false);

      window.location.reload();

    }
    await setLoading(false);
  };

  const changeNFTAddress = async (tokenId: string, tokenaddress: string) => {
    await setLoading(true);
    const busdflag = false;
    try {

      messageProcess("Transferring");

      const retval = await buyPinft(tokenId, "0", 1, tokenaddress, busdflag);


    } catch (e) {

      await setLoading(false);
      window.location.reload();
    }

    await setLoading(false);

  }

  const changeTokenDetails = async (tokenid: string, newPrice: string, newName: string, newTags: string, newDescription: string) => {
    await setLoading(true);

    const signer = await provider1.getSigner();
    try {
      messageProcess("Changing Token Details");

      const txitem1 = await pinftsContract.connect(signer).changeTokenDetails(tokenid, false, newPrice, newName, newTags, newDescription, { from: accountAddress })

      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        messageProcess("Transaction failed");
        throw new Error("Transaction failed");
      } else {
        await axios({
          url: `${config.apiUrl}/pinftupdate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accountAddress}`,
            contentType: false,
            processData: false,
          },
          data: {
            tokenid: tokenid.toString(),
            price: newPrice.toString(),
            tokenname: newName,
            tags: newTags,
            description: newDescription

          },
        });

        messageProcess("Changed Token Details");

        await setLoading(false);

        window.location.reload();

      }
      // }
    } catch (e) {
      console.log(e);
      messageProcess("Failed to change token details");

      await setLoading(false);

      window.location.reload();

    }
    await setLoading(false);
  };



  const buyPinft = async (tokenId: string, price: string, quantity: number, seller: string, busdflag: boolean) => {

    await setLoading(true);

    const signer1 = await provider1.getSigner();
    try {

      const checkbalance = await pinftsContract.balanceOf(seller, tokenId);
      if (!(checkbalance > 0)) {
        alert.show("Insufficient Balance")
        await setLoading(false);
        return false;
      }

      messageProcess("Approving Purchase");

      let receipt1;
      let txitem1;
      let receipt2;
      let txitem2;
    

      let pinftorbusd = "0";
      console.log('current price', price.toString())
      let price2;
      let pinftTaxRate2 = 0.15;
      let  taxcharge = 0;
      let  buyingfee2 = 0 ;
      let  totalapprove = 0;
      if (busdflag) {
        pinftorbusd = "1";
        const balanceme = await busdContract.balanceOf(accountAddress);
        // console.log("busdPinftRate", busdPinftRate.toString())
        // console.log("pinftTaxRate", pinftTaxRate.toString())
        taxcharge = Number(price) * quantity  * pinftTaxRate2 * (busdPinftRate / 1000);
        buyingfee2 = Number(price) * quantity  * (busdPinftRate / 1000) ;
        totalapprove = taxcharge + buyingfee2;
        // console.log("busd balance", balanceme.toString())
        // console.log("taxcharge", taxcharge.toString())
        // console.log("buyingfee2", buyingfee2.toString())
        // console.log("totalapprove", totalapprove.toString())

        if (Number(balanceme) < totalapprove) {
          alert.show("Please top up your busd token balance");
          await setLoading(false);
          return false;
        }
        messageProcess("Approving busd buying fee");
        txitem1 = await busdContract.connect(signer1).approve(busdContract.address, totalapprove.toString());
        receipt1 = await txitem1.wait();

        if (receipt1.status === 0) {
            await setLoading(false);
            console.log("Transaction failed");
            throw new Error("Transaction failed");
          }
          else {
            messageProcess("Tax transfer in process.");

            const txitem2 = await busdContract.connect(signer1).transfer(treasuryReceiver, taxcharge.toString())

            const receipt2 = await txitem2.wait();

            if (receipt2.status === 0) {
              alert.show("Tax transfer failed");
              await setLoading(false);
              return false;

            }
          messageProcess("Tax transfer successful.");

        }

      } else {
        pinftorbusd = "0";
        const balanceme = await pinftagramContract.balanceOf(accountAddress);
         console.log("pinftTaxRate", pinftTaxRate.toString())
        taxcharge = Number(price) * quantity  * pinftTaxRate2 ;
        buyingfee2 = Number(price) * quantity  ;
        totalapprove = taxcharge + buyingfee2;
        console.log("taxcharge", taxcharge.toString())
        console.log("buyingfee2", buyingfee2.toString())
        console.log("totalapprove", totalapprove.toString())

         if (Number(balanceme) < totalapprove) {
          alert.show("Please top up your pinftagram token balance");
          await setLoading(false);
          return false;
        }
        
        messageProcess("Approving buying fee");

        txitem1 = await pinftagramContract.connect(signer1).approve(pinftsContract.address, totalapprove.toString());
        receipt1 = await txitem1.wait();

         if (receipt1.status === 0) {
            await setLoading(false);
            console.log("Transaction failed");
            throw new Error("Transaction failed");
          }
          else {
            messageProcess("Tax transfer in process.");

            const txitem2 = await pinftagramContract.connect(signer1).transfer(treasuryReceiver, taxcharge.toString())

            const receipt2 = await txitem2.wait();

            if (receipt2.status === 0) {
              alert.show("Tax transfer failed");
              await setLoading(false);
              return false;

            }
          messageProcess("Tax transfer successful.");

        }
      }

      // const userapp = await pinftagramContract.allowance(accountAddress, pinftsContract.address)
      // console.log(userapp.toString())
      // const userappbusd = await busdContract.allowance(accountAddress, pinftsContract.address)
      // console.log(userappbusd.toString())
      messageProcess("Buying in process.");
      const txitem3 = await pinftsContract.connect(signer1).buyToken(tokenId.toString(), buyingfee2.toString(), quantity.toString(), seller, pinftorbusd, { from: accountAddress })

      const receipt3 = await txitem3.wait();
      console.log(receipt1)
      if (receipt3.status === 0) {
        alert.show("Buy failed");
        await setLoading(false);

        return false;

      }

      messageProcess("Buy successful.");
      return true;

    } catch (e: any) {
      console.log(e)
      console.log(e.data.data)
      const decodermarket = new InputDataDecoder(Pinfts88.abi);
      const result2 = decodermarket.decodeData(e.data.data);
      console.log(result2)
      messageProcess("Buy failed.");

      await setLoading(false);

      return false;

    }
  };


  document.addEventListener('contextmenu', handelRightClick);

  const messageProcess = async (msgtext: string) => {
    setLoadingMessage(msgtext)
    alert.show(msgtext);
  }

// console.log(pinftslp)

  if ((localStorage?.getItem("isWalletConnected") === "false") ||
  !(typeof (account) !== 'undefined' && typeof (provider) !== 'undefined') ) {
     return <> 
      <Layout style={styles.layout}>
        <Header style={styles.header}>
          {/* <Logo /> */}
          <div style={styles.headerRight}>
            <ChainSelector />
            <ConnectAccount />
          </div>
        </Header>
       
         <div style={{ marginTop: "50px" }}><LandingPage  /></div>
       </Layout>
     </>
  }

  if (loaded !== true) {
    return <>
      <Layout style={styles.layout}>
        <Header style={styles.header}>
          {/* <Logo /> */}
          <div style={styles.headerRight}>
            <ChainSelector />
            <ConnectAccount />
          </div>
        </Header>
       
       
          {(typeof (account) !== 'undefined' && typeof (provider) !== 'undefined') ? 
          <>  <div style={{ marginTop: "400px" }}>
            <ReactLoading type="balls" color="#000000" height={100} width={100} />
            </div></>

           : <div style={{ marginTop: "50px" }}><LandingPage  /></div>
          }
        
      </Layout>

      {/* */}

    </>
  }




  return (
    <Layout style={styles.layout}>
      <Header style={styles.header} >
        <Logo />


        <div style={{ marginTop: "10px", }}>
          <ul style={styles.ul_menu} id='nav-list'>
            <li style={styles.li_menu} ><a style={styles.lia_menu} href={`${httpUrl}/mytokens`}>Portfolio</a></li>
            <li style={styles.li_menu} ><a style={styles.lia_menu} href={`${httpUrl}/mint`}>Mint</a></li>
            <li style={styles.li_menu} ><a style={styles.lia_menu} href={`${httpUrl}/marketplace`}>Marketplace</a></li>
          </ul>
        </div >
        <div style={styles.headerRight}>
          <ChainSelector />
          <ConnectAccount />
        </div>
      </Header >

      <div style={{ display: !loading ? "block" : "block", opacity: !loading ? "1" : "0.2" }}>
        <>
          {loading &&
            (
              <div style={{ height: "100vh" }}>
                <br /> <br /><br /><br /><br />
                <br /> <ReactLoading type="balls" color="#000000" height={100} width={100} />
                <br />
                <span>{loadingMessage}</span>
              </div>
            )



          }


          <Routes>
            <Route
              path="/" element={<LandingPage   />}
            />
            {isAdmin &&
              <Route
                path="home"
                element={
                  <AccountDetails
                    accountAddress={accountAddress}
                    accountBalance={accountBalance}

                    isAdmin={isAdmin}
                    pinftsContract={pinftsContract}
                    provider={provider}

                    myHandle={myHandle}
                    pinftagramContract={pinftagramContract}
                    pinftagramAddress={pinftagramAddress}
                    busdContract={busdContract}

                    busdAddress={busdAddress}
                    busdPinftRate={busdPinftRate}
                    pinftTaxRate={pinftTaxRate}

                  />
                }
              />
            }
            <Route
              path="profile"
              element={
                <Profile
                  accountAddress={accountAddress}
                  myHandle={myHandle}
                  updateProfile={updateProfile}
                  client={client}

                  listings={listings}
                  offers={offers}
                  createListing={createListing}
                  cancelListing={cancelListing}
                  acceptListing={acceptListing}
                  fetchListings={fetchListings}
                  createOffer={createOffer}
                  cancelOffer={cancelOffer}
                  agreeOffer={agreeOffer}
                  acceptbuyoffer={acceptbuyoffer}
                  cancelagreeOffer={cancelagreeOffer}
                  fetchOffers={fetchOffers}

                  offers8={offers8}
                  fetchIndividual={fetchIndividual}
                  pinftsContract={pinftsContract}
                />
              }
            />
            <Route
              path="activity"
              element={
                <Activity
                  accountAddress={accountAddress}
                  myHandle={myHandle}
                  updateProfile={updateProfile}
                  client={client}

                  listings={listings}
                  offers={offers}
                  createListing={createListing}
                  cancelListing={cancelListing}
                  acceptListing={acceptListing}
                  fetchListings={fetchListings}
                  createOffer={createOffer}
                  cancelOffer={cancelOffer}
                  agreeOffer={agreeOffer}
                  acceptbuyoffer={acceptbuyoffer}
                  cancelagreeOffer={cancelagreeOffer}
                  fetchOffers={fetchOffers}
                  offers8={offers8}
                  fetchIndividual={fetchIndividual}
                  pinftsContract={pinftsContract}
                  getTrail={getTrail}
                />
              }
            />

            <Route
              path="mint"
              element={
                <CreateItem
                  mintMyNFT2={mintMyNFT2}
                  nameIsUsed={nameIsUsed}
                  accountAddress={accountAddress}
                  client={client}
                  myHandle={myHandle}

                />
              }
            />
            <Route
              path="/marketplace"
              element={
                <MainPinfts
                  accountAddress={accountAddress}
                  pinfts={pinfts}
                  changeNFTAddress={changeNFTAddress}
                  changeTokenDetails={changeTokenDetails}
                  reportNFTDetails={reportNFTDetails}
                  blockNFTDetails={blockNFTDetails}
                  secretNFTDetails={secretNFTDetails}
                  isAdmin={isAdmin}

                  buyPinft={buyPinft}

                  pinftsContract={pinftsContract}
                  fetchPinfts={fetchPinfts}

                  selectedItem={[]}
                  setSelectedId={setSelectedId}
                  selectedId={0}
                  selectIndividual={selectIndividual}
                  handles={handles}

                  myHandle={myHandle}
                  selectedHandle={selectedHandle}
                  httpUrl={httpUrl}
                  bscUrl1={bscUrl1}
                />
              }
            />

            <Route
              path="marketplace1"
              element={
                <MainPinfts
                  accountAddress={accountAddress}
                  pinfts={pinfts}
                  changeNFTAddress={changeNFTAddress}
                  changeTokenDetails={changeTokenDetails}
                  reportNFTDetails={reportNFTDetails}
                  blockNFTDetails={blockNFTDetails}
                  secretNFTDetails={secretNFTDetails}
                  isAdmin={isAdmin}
                  buyPinft={buyPinft}

                  pinftsContract={pinftsContract}
                  fetchPinfts={fetchPinfts}

                  selectedItem={[]}
                  setSelectedId={setSelectedId}
                  selectedId={0}
                  selectIndividual={selectIndividual}
                  handles={handles}

                  myHandle={myHandle}
                  selectedHandle={selectedHandle}
                  httpUrl={httpUrl}
                  sendTip={sendTip}
                  bscUrl1={bscUrl1}
                />
              }
            />

            <Route
              path="mytokens"
              element={
                <MainPinfts
                  accountAddress={accountAddress}
                  pinfts={pinfts}
                  changeNFTAddress={changeNFTAddress}
                  changeTokenDetails={changeTokenDetails}
                  reportNFTDetails={reportNFTDetails}
                  blockNFTDetails={blockNFTDetails}
                  secretNFTDetails={secretNFTDetails}
                  isAdmin={isAdmin}
                  buyPinft={buyPinft}

                  pinftsContract={pinftsContract}
                  fetchPinfts={fetchPinfts}

                  selectedItem={[]}
                  setSelectedId={setSelectedId}
                  selectedId={0}
                  selectIndividual={selectIndividual}
                  handles={handles}

                  myHandle={myHandle}
                  selectedHandle={selectedHandle}
                  httpUrl={httpUrl}
                  sendTip={sendTip}
                  bscUrl1={bscUrl1}
                />
              }
            />

            <Route
              path="indivtoken"
              element={
                <IndivToken
                  accountAddress={accountAddress}
                  pinfts={pinfts}
                  changeNFTAddress={changeNFTAddress}
                  changeTokenDetails={changeTokenDetails}
                  reportNFTDetails={reportNFTDetails}
                  blockNFTDetails={blockNFTDetails}
                  secretNFTDetails={secretNFTDetails}
                  isAdmin={isAdmin}
                  buyPinft={buyPinft}

                  pinftsContract={pinftsContract}
                  fetchPinfts={fetchPinfts}
                  listings={listings}
                  offers={offers}
                  myListings={myListings}
                  myOffers={myOffers}

                  createListing={createListing}
                  cancelListing={cancelListing}
                  acceptListing={acceptListing}
                  fetchListings={fetchListings}
                  createOffer={createOffer}
                  cancelOffer={cancelOffer}
                  agreeOffer={agreeOffer}
                  acceptbuyoffer={acceptbuyoffer}
                  cancelagreeOffer={cancelagreeOffer}
                  fetchOffers={fetchOffers}
                  selectedItem={selectedItem}
                  setSelectedId={setSelectedId}
                  selectedId={selectedId}
                  selectIndividual={selectIndividual}
                  handles={handles}

                  myHandle={myHandle}
                  httpUrl={httpUrl}
                />
              }
            />

            <Route path="/404" element={<div>Not found</div>} />
            <Route path="*" element={<Navigate replace to="/404" />} />

          </Routes>


        </>

      </div>

      {/* <Footer style={styles.footer}>
        <div style={{ display: "block" }}>
          Please leave a ⭐️ on this{" "}
          <a href="https://finance.pinftagram" target="_blank" rel="noopener noreferrer">
            Pinftagram
          </a>
          , if you like it!
        </div>
      </Footer> */}
    </Layout >
  );
}

export const Logo = () => {
  return (
    <div style={{ paddingTop: "15px", width: "200px" }}>
      <img src={pinftagram_logo} alt="pinftagram_logo" width="188px" />
    </div>
  )
};

export default Application;
