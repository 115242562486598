/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton ,
  TelegramShareButton,
  WhatsappShareButton,
   LinkedinShareButton ,
   TwitterShareButton
   // TelegramIcon,
   // WhatsappIcon,
   //  FacebookIcon,
   //  TwitterIcon,
   //  LinkedinIcon,
       
  } from "react-share";
// import {
//   FacebookShareCount,
//   PinterestShareCount,
//   VKShareCount,
//   OKShareCount,
//   RedditShareCount,
//   TumblrShareCount,
//   HatenaShareCount,
//   FacebookShareButton,
//   FacebookMessengerShareButton,
//   FacebookMessengerIcon,
//   LinkedinShareButton,
//   TwitterShareButton,
//   PinterestShareButton,
//   VKShareButton,
//   OKShareButton,
//   TelegramShareButton,
//   WhatsappShareButton,
//   RedditShareButton,
//   EmailShareButton,
//   TumblrShareButton,
//   LivejournalShareButton,
//   MailruShareButton,
//   ViberShareButton,
//   WorkplaceShareButton,
//   LineShareButton,
//   WeiboShareButton,
//   PocketShareButton,
//   InstapaperShareButton,
//   HatenaShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   LinkedinIcon,
//   PinterestIcon,
//   VKIcon,
//   OKIcon,
//   TelegramIcon,
//   WhatsappIcon,
//   RedditIcon,
//   TumblrIcon,
//   MailruIcon,
//   EmailIcon,
//   LivejournalIcon,
//   ViberIcon,
//   WorkplaceIcon,
//   LineIcon,
//   PocketIcon,
//   InstapaperIcon,
//   WeiboIcon,
//   HatenaIcon,
// } from "react-share";

// socialmedia-icon-holder
 // className="socialmedia-holder"

function SocialShare(props)  {
   
    return (
      <>
       <div>
         
        <FacebookShareButton
        url={ props.shareUrl}
        quote={ props.title}
        hashtag= "#hashtag" 
        description={ props.message}
      >
      <img src='./images/FBBlack.svg' alt="fb" width={32}/>

      
         
      </FacebookShareButton>
         &nbsp; &nbsp; 
          <WhatsappShareButton
            url={ props.shareUrl}
            title={ props.title}
            separator=":: "
             
          >
             <img src='../images/WhatsAppBlack.svg' alt="fb" width={32}/>

             
          </WhatsappShareButton>
    
         &nbsp; &nbsp; 
          <LinkedinShareButton url={ props.shareUrl}  >
            <img src='../images/InBlack.svg' alt="fb" width={32}/>


          </LinkedinShareButton>
          &nbsp; &nbsp; 
          <TwitterShareButton
            url={ props.shareUrl}
            title={ props.title}
             
          >
           <img src='../images/TWBlack.svg' alt="fb" width={32}/>

          </TwitterShareButton>

          &nbsp; &nbsp; 
          <TelegramShareButton
            url={ props.shareUrl}
            title={ props.title}
            
          >
         <img src='../images/TGBlack.svg' alt="fb" width={32}/>
          </TelegramShareButton>

           
       
        </div>
        </>
    );
  
}

SocialShare.propTypes = {
  
  title: PropTypes.string,
  message: PropTypes.string,
  shareUrl: PropTypes.string
};
export default SocialShare


 // &nbsp; &nbsp; 
 //          <EmailShareButton
 //            url={this.props.shareUrl}
 //            subject={this.props.title}
 //            body="https://pinftagram.netlify.app"
             
 //          >
 //            <EmailIcon size={32} round />
 //          </EmailShareButton>
        
