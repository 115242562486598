/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import axios from 'axios';
import ReactLoading from "react-loading";
// import SocialShare from "../SocialShare"
import { ConnectToExplorer } from "../Common";

function Listings(props) {

  const history = useNavigate();

  const [listingsProfile, setListingsProfile] = useState([]);

  const [loadingListings, setLoadingListings] = useState(true);


  async function fetchData() {
    if (window.location.href.includes('activity')) {

      const listings3 = await Promise.all(props.listings.map(async i => {

        // const selecteduri = await props.pinftsContract.uri(i.tokenid.toString())

        const selectedpinft = await props.pinftsContract.allPinfts(i.tokenid.toString())

        const meta = await axios.get(selectedpinft.tokenuri);

        const item = {

          price: selectedpinft.price,
          quantity: selectedpinft.quantity,
          tokenid: Number(selectedpinft.tokenid),
          mintedby: selectedpinft.mintedby,
          numberoftransfers: selectedpinft.numberoftransfers,
          tokenname: selectedpinft.tokenname,
          image: meta.data.image,
          imagetype: meta.data.imagetype,
          image2: meta.data.image2,
          imagetype2: meta.data.imagetype2,
          image3: meta.data.image3,
          imagetype3: meta.data.imagetype3,
          tags: selectedpinft.tags,
          description: selectedpinft.description,

          nfthandle: meta.data.nfthandle,
          posttime: selectedpinft.posttime,
          saletime: selectedpinft.saletime,

          unitprice: i.unitprice,

          id: i.id,
          selleraddress: i.selleraddress

        }

        return item
      }


      ))

      await setListingsProfile(listings3);

    } else {
      await setListingsProfile(props.listings);
    }
    setLoadingListings(false)
  }


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listings]);


  if (loadingListings === true) return (
    <>
      <br />

      <ReactLoading type="balls" color="#000000"
        height={100} width={100} />
      <br />
    </>
  )

  return (
    <>
      {listingsProfile.length > 0 &&
        <div style={{ padding: "8px" }}>

          <div className="text-block-16">
            {(window.location.href.includes('activity') ? "My Listings" : "Listings")}
          </div>
          <div >
            {listingsProfile.map((listitem) => (
              <div key={listitem.id}>
                {window.location.href.includes('activity') &&
                  <>

                    <img alt="imagetype" src={listitem.image}
                      style={{ cursor: "pointer", display: "block", maxHeight: "600px" }}
                      aria-hidden="true"
                      onClick={() =>
                        props.fetchIndividual(
                          listitem.tokenid.toString(),
                          history
                        )
                      }
                    />
                    <br />

                    <br />{listitem.tokenname}
                    <br />{listitem.description}
                    <br />{listitem.tags}
                    <br />
                  </>
                }
                Seller :
                <ConnectToExplorer
                  accountAddress={listitem.selleraddress}
                  handle={listitem.handlemintedby}
                />
                <br /> <Moment fromNow>{parseInt(listitem.posttime) * 1000}</Moment>

                 <br />{Number(listitem.unitprice).toString()} PINFT
                 <br /> <br /> 
                {props.accountAddress.toLowerCase() === listitem.selleraddress.toLowerCase() ?

                  <button type="button" style={{
                    borderRadius: "50px", fontSize: "18px",
                    backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                  }}

                    onClick={() =>
                      props.cancelListing(
                        listitem.id.toString()
                      )
                    }>
                    Cancel
                  </button> :
                  <>
                    <button type="button" style={{
                      borderRadius: "50px", fontSize: "18px",
                      backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                    }}

                      onClick={() =>
                        props.acceptListing(
                          listitem.id.toString(),
                          listitem.tokenid.toString(),
                          (listitem.unitprice * 10 ** 18).toString(),
                          listitem.quantity.toString(),
                          listitem.selleraddress,
                          false
                        )}
                    >
                      Buy with Pinft
                    </button>&nbsp;&nbsp;&nbsp;
                    <button type="button" style={{
                      borderRadius: "50px", fontSize: "18px",
                      backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                    }}

                      onClick={() =>
                        props.acceptListing(
                          listitem.id.toString(),
                          listitem.tokenid.toString(),
                          listitem.unitprice.toString(),
                          listitem.quantity.toString(),
                          listitem.selleraddress,
                          true
                        )}
                    >
                      Buy with BUSD
                    </button>
                  </>}
                <br /> <br />
              </div>
            ))}

          </div>
          <br />

          {/*
  <SocialShare
      title={props.pinft.tokenname.substr(0, 50)}
      message={props.pinft.description.substr(0, 100)}
      shareUrl= {props.shareUrl}
  /> 
  */}

          <br />
        </div>
      }
    </>
  )

}

export default Listings;