/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import config from "../../config.json";
// import { create as ipfsHttpClient } from 'ipfs-http-client';
// import { capitalize, map } from "lodash";

// import Loading from "../Loading/Loading";
// import {ConnectToExplorer, ConnectToGallery} from   "../Common";
import Listings from "../Listings";
import Offers from "../Offers";

import { ConnectToExplorer, ConnectToGallery, ShowIndiv } from "../Common";
// import Offers8 from "../Offers/offers8";
// import user7 from "../../images/user-7.svg"
// import ActivityTable from "./showtable"
// const titleCase = (str) => map(str.split(" "), capitalize).join(" ");


export default function Activity(props) {



  const [loadedActivity, setLoadedActivity] = useState(false)

  const [activityTrail, setActivityTrail] = useState([])


  // const [loading, setLoading] = useState(false);

  // const {myHandle} = props ;


  // const {handleimageuri, handlename, handletagline} =  myHandle;

  // const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0');
  const navigate = useNavigate();

  const show000 = true;

  useEffect(() => {
    async function setupActivity() {
      setLoadedActivity(false);

      await props.fetchListings("address", props.accountAddress);

      await props.fetchOffers("selleraddress", props.accountAddress);

      await props.fetchOffers("buyeraddress", props.accountAddress);

      //   console.log(props.offers8)

      // console.log();

      const activitytrail = await props.getTrail(`${config.apiUrl}/trailindex`, props.accountAddress)
      console.log(activitytrail)
      setActivityTrail(activitytrail.sort((a, b) => a.tokenid < b.tokenid ? 1 : -1));

      setLoadedActivity(true);

    }
    setupActivity()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function openPortfolio() {
    navigate(`/mytokens`);
  }

  const friendlytt = (tt) => {

    if (tt === "LISTINGNEW") {
      return "New Listing"
    }
    if (tt === "LISTINGCLOSE") {
      return "Close Listing"
    }
    if (tt === "OFFERNEW") {
      return "New Offer"
    }
    if (tt === "OFFERCLOSE") {
      return "Close Offer"
    }
    return tt
  }

  if (!loadedActivity) {
    return <div>.</div>
  }

  if (!props.listings && !props.offers) {
    return <div>
      <button onClick={(e) => openPortfolio(e)} type="button"
        style={{
          borderRadius: "50px", fontSize: "18px",
          backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
        }} >

        Portfolio
      </button>
    </div>
  }


  return (

    <div className="_2ndmainsection">
      <div className="user-profile-container">
        <br /><br />
        <button onClick={(e) => openPortfolio(e)} type="button"
          style={{
            borderRadius: "50px", fontSize: "18px",
            backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
          }} >

          Portfolio
        </button>
        <br /><br />
        <Listings
          listings={props.listings}
          accountAddress={props.accountAddress}
          createListing={props.createListing}
          cancelListing={props.cancelListing}
          acceptListing={props.acceptListing}
          fetchIndividual={props.fetchIndividual}
          pinftsContract={props.pinftsContract}
        />

        <Offers
          offers={props.offers}
          accountAddress={props.accountAddress}
          createOffer={props.createOffer}
          cancelOffer={props.cancelOffer}
          agreeOffer={props.agreeOffer}
          acceptbuyoffer={props.acceptbuyoffer}
          cancelagreeOffer={props.cancelagreeOffer}
          fetchIndividual={props.fetchIndividual}
          pinftsContract={props.pinftsContract}
        />
      </div>
      <br /><br />
      <div style={{ margin: "auto" }}>
        <div className="text-block-16">
          Activity Trail
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ padding: "4px" }}>Type</th>
              <th style={{ padding: "4px" }}>Token</th>
              <th style={{ padding: "4px" }}>Price</th>
              <th style={{ padding: "4px" }}>Quantity</th>
              <th style={{ padding: "4px" }}>Seller</th>
              <th style={{ padding: "4px" }}>Buyer</th>

              <th style={{ padding: "4px" }}>Created</th>
            </tr>
          </thead>
          <tbody>
            {activityTrail.map(function (activitytrail1) {
              return (<tr key={activitytrail1.posttime} >
                <td style={{ padding: "4px" }}>{friendlytt(activitytrail1.trailtype)}</td>
                <td style={{ padding: "4px" }}>
                  <ShowIndiv tokenId={activitytrail1.tokenid.toString()} />
                </td>
                <td style={{ padding: "4px" }}>
                  <NumberFormat value={(activitytrail1.unitprice * 10 ** -18).toString()} displayType='text' thousandSeparator={show000} decimalScale={3} />

                </td>
                <td style={{ padding: "4px" }}>{activitytrail1.quantity}</td>
                <td style={{ padding: "4px" }}>
                  <ConnectToGallery
                    accountAddress={activitytrail1.selleraddress}
                    addresstype="minted" />
                  <ConnectToExplorer
                    accountAddress={activitytrail1.selleraddress}
                    handle="" />

                </td>
                <td style={{ padding: "4px" }}>
                  <ConnectToGallery
                    accountAddress={activitytrail1.buyeraddress}
                    addresstype="minted" />
                  <ConnectToExplorer
                    accountAddress={activitytrail1.buyeraddress}
                    handle="" />
                </td>

                <td style={{ padding: "4px" }}>
                  <Moment fromNow>{parseInt(activitytrail1.posttime) * 1000}</Moment> </td>
              </tr>)
            })}
          </tbody>
        </table>


      </div>
    </div>

  )
}