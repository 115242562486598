/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';

import axios from 'axios';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { useLocation, useNavigate } from "react-router-dom";

import ReactLoading from "react-loading";
import { useFirstMountState } from 'react-use';
import ReactPaginate from 'react-paginate';

import { ConnectToExplorer, ConnectToGallery } from "../Common";
import SocialShare from "../SocialShare"

import "./styles.css";
import NFTImage from "../NFTImage/NFTImage";

import magglass from "./MagGlass.png";


const MainPinfts = (props: any) => {



  const [showPinfts, setShowPinfts] = useState<any>(null);
  const [loadingState, setLoadingState] = useState('not-loaded')

  const [mintedOwned, setMintedOwned] = useState('minted');

  const [minterAddress, setMinterAddress] = useState('');

  const [gridDisplayType, setGridDisplayType] = useState("");

  const [newTipPrice, setNewTipPrice] = useState("1");

  const [newSearchText, setNewSearchText] = useState("");

  const [queryText, setQueryText] = useState("");

  const [shareUrl, setShareUrl] = useState("");

  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [items, setItems] = useState(Array<number>);

  const [currentItems, setCurrentItems] = useState<any>(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);


  // const {myHandle} = props ;

  // const {handleimageuri, handlename, handletagline} =  myHandle;

  const navigate = useNavigate();


  const isFirstMount = useFirstMountState();




  const callEditProfile = (e: any) => {
    e.preventDefault();
    navigate(`/profile`);
  };

  const callActivity = (e: any) => {
    e.preventDefault();
    navigate(`/activity`);
  };

  async function updatePinftQuery(e: any) {
    e.preventDefault();


    const reg = RegExp(/^0x[a-fA-F0-9]{40}$/);
    if (reg.test(newSearchText)) {
      await navigate(`/marketplace1/?minter=${newSearchText}`);
      window.location.reload();
    } else {
      await navigate(`/marketplace1/?querytext=${newSearchText}`);
      window.location.reload();
    }

  }

  const callMintOwned = (e: any) => {
    e.preventDefault();
    if (mintedOwned === "minted") {
      setMintedOwned("owned");
    } else {
      setMintedOwned("minted");
    }
  };

  const convertpinft = (urlin: string) => {
    return urlin.replace("https://ipfs.infura.io", "https://pinftagram.infura-ipfs.io");
  }



  const search = useLocation().search;

  async function fetchData() {

    setLoadingState('loading');

    const minteraddress: any = await new URLSearchParams(search).get("minter");

    await setMinterAddress(minteraddress);

    const querytext: any = await new URLSearchParams(search).get("querytext");

    await setQueryText(querytext);

    const griddisplaytype = await (window.location.href.includes('marketplace')) ? "marketplace" : "portfolio"

    const fetchaddress = minteraddress === null ? props.accountAddress : minteraddress;

    await setGridDisplayType(griddisplaytype);
    const pinfts = await props.fetchPinfts(griddisplaytype, fetchaddress, mintedOwned, querytext);

    // console.log(pinfts[0])

    // const tokenUri = "https://pinftagram.infura-ipfs.io/ipfs/QmVb9eH8S3YRvzpRnU8iyeigomkCTDs1RGnoEYx8DtgkxT";
    // const meta = await axios.get(tokenUri);
    // return;


    const pinfts2 = await Promise.all(pinfts.map(async (i: { tokenuri: string; tokenid: any; mintedby: string; price: any; quantity: any; numberoftransfers: any; tokenname: any; tags: any; description: any; posttime: any; saletime: any; }) => {

      const tokenUri = convertpinft(i.tokenuri);
      const meta = await axios.get(tokenUri);


      const totalmytokens = await props.pinftsContract.balanceOf(props.accountAddress, i.tokenid);

      let totalmintertokens = totalmytokens;
      if (i.mintedby.toLowerCase() !== props.accountAddress.toLowerCase()) {
        totalmintertokens = await props.pinftsContract.balanceOf(i.mintedby, i.tokenid);
      }

      let handlemintedby = []
      if (props.handles.length > 0) {
        handlemintedby = props.handles.filter((item: { creator: string; }) => item.creator.toLowerCase() === i.mintedby.toLowerCase());
      }
      // handlemintedby.handleName
      // let price = ethers.utils.formatUnits(i.price.toString(), 'ether')


      const item = {
        price: i.price,
        quantity: i.quantity,
        tokenid: Number(i.tokenid),
        mytokens: totalmytokens,
        mintertokens: totalmintertokens,

        mintedby: i.mintedby,
        numberoftransfers: i.numberoftransfers,
        tokenname: i.tokenname,
        image: meta.data.image,
        imagetype: meta.data.imagetype,
        image2: meta.data.image2,
        imagetype2: meta.data.imagetype2,
        image3: meta.data.image3,
        imagetype3: meta.data.imagetype3,
        tags: i.tags,
        description: i.description,

        nfthandle: meta.data.nfthandle,
        posttime: i.posttime,
        saletime: i.saletime,
        handlemintedby: handlemintedby.length > 0 ? handlemintedby[0].handlename : ""
      }
      return item
    }


    ))

    const shareurl = `${props.httpUrl}/marketplace1/?minter=${minteraddress}`;

    await setShareUrl(shareurl);

    setShowPinfts(pinfts2.reverse());



    const items1: Array<number> = Array.from(Array(pinfts2.length).keys());
    await setItems(items1)

    const endOffset = itemOffset + itemsPerPage;
    //  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const items2 = items1.slice(itemOffset, endOffset)
    await setCurrentItems(items2)
    await setPageCount(Math.ceil(items1.length / itemsPerPage));

    await setLoadingState('loaded')

  }


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (!(isFirstMount)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintedOwned]);





  useEffect(() => {
    if (items !== null) {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      console.log(items.slice(itemOffset, endOffset));
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage]);

  //   // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
    window.scrollTo(0, 0)
  };

function enterhandle(event: any){
  console.log(event.code)
    if(event.code === "Enter"){
    //write your specific code from here
    //  alert.show("You are searching: ");
    updatePinftQuery(event)
    console.log("searching")
    }
  return false;
}

  function Items({ currentItems1 }: { currentItems1: any }) {
    return (
      <>
        {currentItems1 &&
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1050: 4 }}>
            <Masonry columnsCount={4} gutter="10px">
              {currentItems1.map((item: any) => (

                <div key={showPinfts[item].tokenid}>
                  <NFTImage
                    pinft={showPinfts[item]}

                    id={showPinfts[item].tokenid}
                    selectedItem={props.selectedItem}
                    selectedId={props.selectedId}
                    selectIndividual={props.selectIndividual}
                    history={navigate}
                  />
                  <div className="textholderblock" key={showPinfts[item].tokenid}>
                    <div className="titleblock">
                      <h1 className="heading">{showPinfts[item].tokenname}</h1>
                    </div>

                    {!(window.location.href.includes('marketplace')) &&
                      <>
                        {showPinfts[item].description.substr(0, 188)}
                      </>
                    }
                    {(window.location.href.includes('marketplace') && minterAddress === null) &&
                      <div className="addressblock">

                        <div className="text-block-3">
                          Minted by :
                          <ConnectToExplorer
                            accountAddress={showPinfts[item].mintedby}
                            handle={showPinfts[item].handlemintedby} />
                          <ConnectToGallery
                            accountAddress={showPinfts[item].mintedby}
                            addresstype="minted"
                            handle={showPinfts[item].handlemintedby}
                            currentAddress={props.accountAddress}
                          />
                        </div>
                      </div>
                    }
                    {showPinfts[item].price > 0 &&
                      <span className="text-base" >{ethers.utils.formatEther(showPinfts[item].price.toString())}&nbsp;Pinft</span>}
                    &nbsp;



                  </div>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        }
      </>
    );
  }

  if ((loadingState !== 'loaded') || ((showPinfts === null) || typeof (showPinfts) === 'undefined')) {
    return (
      <>
        <br /> <br /><br /><br /><br />

        <ReactLoading type="balls" color="#000000"
          height={100} width={100} />
        <br />
      </>)
  }

 
  return (
    <div className="body-5">
      <div  >
        {(gridDisplayType === "marketplace" && minterAddress === null) &&

          <div className="wf-section">
            <br /><br /><br /><br /> <br /><br /><br /><br />
            <div className="search-bar" style={{ margin: "auto" }}>
              <div className="magglass" >
                <img src={magglass} width="15" alt="search"
                  onClick={(e) => updatePinftQuery(e)} aria-hidden="true" style={{ minHeight: "15px", cursor: "pointer" }} />
              </div>
              <input className="search-bar-input"
                placeholder="Search by address or text"
                autoComplete="off"
                name="searchText"
                id="searchText"
                value={newSearchText}
                onChange={(e) => setNewSearchText(e.target.value)}
                onKeyPress={(e)=>enterhandle(e)}
              />
            </div>

          </div>
        }
        <br /> <br />
        {minterAddress !== null &&
          <>
            <br /><br /><br /><br /><br />
            <div className="portfolio-profile-box ">

              <div className="port-profile-container ">
                <div className="portfoliocard">
                  <div className="port-handle-text-holder ">


                    <div className="text-block-4">
                      {props.selectedHandle.handleimageuri !== "" && <img src={props.selectedHandle.handleimageuri} width="100" alt="avatar"
                        style={{ borderRadius: "50px" }} />}
                    </div>
                    <br />
                    <div className="text-block-4">{props.selectedHandle.handlename}</div>
                    <br />
                    <div className="text-block-7">{props.selectedHandle.handletagline}</div>
                    <br />
                    <div className="text-block-7">
                      <ConnectToExplorer
                        accountAddress={minterAddress}
                        handle=''
                      />

                    </div>
                    <br />

                    <div className="text-block-7">

                      <input
                        autoComplete="off"
                        required
                        pattern="^\d*(\.\d{0,2})?$"
                        name="tipPrice"
                        id="tipPrice"
                        value={newTipPrice}
                        className="mint-input-box"
                        style={{ width: "100px", height: "35px" }}
                        onChange={(e) => setNewTipPrice(e.target.value)}
                      />

                      &nbsp;&nbsp;
                      <button type="button"
                        style={{
                          borderRadius: "50px", fontSize: "18px",
                          backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                        }}

                        onClick={() =>
                          props.sendTip(
                            minterAddress,
                            ethers.utils.parseEther(newTipPrice)
                          )
                        }
                        disabled={!(newTipPrice !== "0")}   >
                        Tip Pinft
                      </button>


                    </div>
                    <br />

                    <SocialShare
                      title={props.selectedHandle.handlename}
                      message={props.selectedHandle.handletagline}
                      shareUrl={shareUrl}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </>
        }

        {(gridDisplayType === "portfolio") &&
          <>
            <br /><br /><br /><br /><br /><br />
            <div className="portfolio-profile-box">
              <div className="port-profile-container">
                <div className="portfoliocard">
                  <div className="port-profile-image-holder">
                    {props.myHandle.handleimageuri ? (<img alt="file" className="image-3" width="125" src={props.myHandle.handleimageuri} />)
                      : (<img src="./images/Profile-Blank.png" loading="lazy" width="125" alt="" className="image-3" />)}
                  </div>
                  <div className="port-handle-text-holder">
                    <div className="text-block-4">{props.myHandle.handlename}</div>
                    <div>{props.myHandle.handletagline}</div>
                    <div className="text-block-5">
                      <ConnectToExplorer accountAddress={props.accountAddress} handle='' />

                    </div>
                    <div className="text-block-7">
                      <button type="button" style={{ borderRadius: "50px", fontSize: "18px", color: "black", height: "35px", width: "135px", padding: "5px" }} onClick={(e) => callActivity(e)}>Activity</button>
                      &nbsp;&nbsp;
                      <button type="button" style={{ borderRadius: "50px", fontSize: "18px", color: "black", height: "35px", width: "135px", padding: "8px", marginLeft: "5px" }} onClick={(e) => callEditProfile(e)}>Profile</button>
                    </div>
                  </div>
                  <div className="port-editbtn-holder">
                    <div className="text-block-7">
                      <button type="button" style={{ borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px", height: "35px", width: "139px", padding: "5px" }} onClick={(e) => callMintOwned(e)}>{mintedOwned === 'minted' ? <span style={{ fontSize: "26px" }}>Minted</span> : <span>Minted</span>}</button></div>
                    <div className="text-block-8">
                      <button type="button" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px", height: "35px", width: "139px", padding: "5px" }} onClick={(e) => callMintOwned(e)}>{mintedOwned !== 'minted' ? <span style={{ fontSize: "26px" }}>Owned</span> : <span>Owned</span>}</button></div>
                  </div>
                  <div className="port-numberof-holder">
                    <div className="text-block-9">Total Number of Pinfts : {showPinfts.length}</div>
                  </div>
                  <br />

                </div>
              </div>
            </div>
          </>
        }

        <>

          <Items currentItems1={currentItems} />

          <br /><br />
          {(currentItems) &&
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={() => null}
              containerClassName="pagination"
              activeClassName="active"
              marginPagesDisplayed={0}
            />
          }
          <br /><br />
        </>



      </div>
    </div>

  );
}





export default MainPinfts;



