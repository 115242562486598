/* eslint-disable */
/* no-await-in-loop */
import React, { useState, useEffect } from "react";
// import { useWeb3React } from '@web3-react/core'
import NumberFormat from 'react-number-format';
import { ethers } from 'ethers';
//import { useAlert } from 'react-alert'
import { Link, Navigate } from "react-router-dom";
import Papa from 'papaparse';
import axios from 'axios';
import airdrop1 from '../../contracts/airdrop.json';
import { ConnectToExplorer } from "../Common";
import user7 from "../../images/user-7.svg"

// import _nftairdrops from '../../contracts/airdropnft.json';

const AccountDetails = ({ accountAddress, accountBalance, isAdmin,
  pinftsContract, provider, myHandle, pinftagramContract, pinftagramAddress, busdContract,
  busdAddress, busdPinftRate, pinftTaxRate }: {
    accountAddress: string, accountBalance: string, isAdmin: boolean,
    pinftsContract: any, provider: any, myHandle: any, pinftagramContract: any, pinftagramAddress: string, busdContract: any, busdAddress: string, busdPinftRate: number, pinftTaxRate: number
  }) => {

  const [loading, setLoading] = useState(false);

  const [myPinftBalance, setMyPinftBalance] = useState(0);
  const [myBusdBalance, setMyBusdBalance] = useState(0);

  const [contractBalance, setContractBalance] = useState(0);
  const [pinftBalance, setPinftBalance] = useState(0);

  const [mintingPrice, setMintingPrice] = useState(0);
  const [mintingReceiver, setMintingReceiver] = useState('')

  const [treasuryReceiver, setTreasuryReceiver] = useState('')

  const [csvdata, setCsvdata] = useState(null)


  const [inProcessOfCalling, setInProcessOfCalling] = useState(false);

  const show000 = true;
  // const alert = useAlert();
  // const history = useNavigate();

  useEffect(() => {

    const fetchAccount = async () => {

      if (pinftsContract !== null) {


        let pinftbalance = await provider.getBalance(pinftagramAddress);
        pinftbalance = ethers.utils.formatEther(pinftbalance);
        setPinftBalance(pinftbalance);

        const mintingreceiver = await pinftsContract.mintingReceiver();
        setMintingReceiver(mintingreceiver);

        const treasuryreceiver = await pinftsContract.treasuryReceiver();
        setTreasuryReceiver(treasuryreceiver);

        const mintingprice = await pinftsContract.mintingPrice()
        setMintingPrice(Number(mintingprice.toString()));

        let contractbalance = await provider.getBalance(pinftsContract.address);
        contractbalance = ethers.utils.formatEther(contractbalance);
        setContractBalance(contractbalance);

        let mypinftbalance = await pinftagramContract.balanceOf(accountAddress);
        mypinftbalance = ethers.utils.formatEther(mypinftbalance);
        setMyPinftBalance(mypinftbalance);

        let mybusdbalance = await busdContract.balanceOf(accountAddress);
        mybusdbalance = ethers.utils.formatEther(mybusdbalance);
        setMyBusdBalance(mybusdbalance);

        //  getallPinfts();

      }

    }

    fetchAccount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinftsContract])



  // for(let i = 0;i<_nftairdrops.length;i++){
  //      console.log(_nftairdrops[i]);
  // }

  const withdrawfromContract = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    const signer = provider.getSigner();
    try {
      const txitem1 = await pinftsContract.connect(signer).clearStuckBalance_sender("100");

      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {

        throw new Error("Transaction failed");
      } else {
        setLoading(false);
        //      window.location.reload();
      }
    } catch (err) {
      // console.log(err);
      setLoading(false);
      //     window.location.reload();
    }

  };


  // ********* NFT Airdrop ************
  const getJsonData = async () => {

    // //  nextaddressctr++;
    // //   balance1 = await  pinftsContract.balanceOf( _nftairdrops[nextaddressctr].holderaddress, "4");
    // //  console.log(_nftairdrops[nextaddressctr].holderaddress, (balance1  ).toString());


    //   // console.log(_nftairdrops);


    //   let index  = 1;
    //   // const batch  = 0;
    //   const batchsize  = 50;
    //   // let web3 = new Web3();

    //    const  distribAddressData  = [];
    //    const  distribAmountData  = [];
    //    let  allocAddressData  = [];
    //    let  allocAmountData  = [];

    //    let  allocAddress   = "";
    //    let  allocAmount   = "";

    //   let sumamount  = 0;

    //   const tokenid  = _nftairdrops[0].tokenId;

    //    try {
    //      const mapretval = _nftairdrops.map( item => {

    //      const nxtaddress  = item.holderaddress;
    //      const nxtamount  = Number(item.dropqty);

    //      const isAddress = true; // web3.utils.isAddress(nxtaddress);
    //      if (isAddress && nxtaddress !== null && nxtaddress !== '') {
    //         allocAddress = nxtaddress;
    //         allocAmount = ethers.utils.parseEther(nxtamount.toString()).toString();
    //       // console.log(allocAddress)
    //       // console.log(allocAmount)   

    //         allocAddressData.push(allocAddress);
    //         allocAmountData.push(allocAmount);
    //         sumamount +=   nxtamount;
    //         if (index < Number( batchsize)) {
    //           index++;
    //          } else {
    //           distribAddressData.push(allocAddressData);
    //           distribAmountData.push(allocAmountData);
    //          allocAddressData  = [];
    //          allocAmountData  = [];
    //          index=1;
    //         }

    //      }
    //      return true;
    //     }
    //   )
    //     if (index>0) {
    //       distribAddressData.push(allocAddressData);
    //       distribAmountData.push(allocAmountData);
    //     }
    //     sumamount +=  100;
    //    const sumallocAmount = ethers.utils.parseEther(sumamount.toString());

    //    // console.log("sumallocAmount", (sumallocAmount  ).toString());

    //   const signer =  provider.getSigner( );


    //   const balance = await  pinftsContract.balanceOf(accountAddress, tokenid);
    //   // console.log("token contract balance", (balance).toString());

    //   return;


    //   for (let i = 0; i < distribAddressData.length; i++) {
    //     // console.log(distribAddressData[i]);
    //     if (distribAddressData[i].length > 0) {
    //       // variable quantity  
    //       //  await pinftsContract.connect(signer).multiTransferNFT( tokenid, distribAddressData[i], distribAmountData[i])
    //       // fixed quantity 1
    //       await pinftsContract.connect(signer).multiTransfer_fixedNFT( tokenid, distribAddressData[i])
    //     }

    //   }

    //    } catch (error) {
    //    // console.log(error);
    //    }


  }
  // ********* NFT Airdrop ************




  // Airdrop Pinftagram ERC20
  // const fetchCsv  = async () => {
  //      return fetch(airdrop1).then(function (response) {
  //          const reader = response.body.getReader();
  //          const decoder = new TextDecoder('utf-8');

  //          return reader.read().then(function (result) {
  //              return decoder.decode(result.value);
  //          });
  //      });
  //    }

  //    const getData = async (result) => {
  //        await setCsvdata(result.data);
  //    }

  const getJsonDataERC20 = async () => {

    console.log(airdrop1);

    let index = 1;
    const batch = 0;
    const batchsize = 50;
    // let web3 = new Web3();

    const distribAddressData = [];
    const distribAmountData = [];
    let allocAddressData: any = [];
    let allocAmountData: any = [];

    let allocAddress = "";
    let allocAmount;

    let sumamount = 0;

    try {
      const retdata = airdrop1.map(item => {

        const nxtaddress = item.holderaddress;
        const nxtamount = item.dropqty;

        const isAddress = true; // web3.utils.isAddress(nxtaddress);
        if (isAddress && nxtaddress !== null && nxtaddress !== '') {
          allocAddress = nxtaddress;
          allocAmount = ethers.utils.parseEther(nxtamount);

          allocAddressData.push(allocAddress);
          allocAmountData.push(allocAmount);
          sumamount += Number(item.dropqty);
          if (index < Number(batchsize)) {
            index++;
          } else {
            distribAddressData.push(allocAddressData);
            distribAmountData.push(allocAmountData);
            allocAddressData = [];
            allocAmountData = [];
            index = 1;
          }

        }
        return true;
      }
      )
      if (index > 0) {
        distribAddressData.push(allocAddressData);
        distribAmountData.push(allocAmountData);
      }
      sumamount += 100;
      const sumallocAmount = ethers.utils.parseEther(sumamount.toString());
      console.log("sumallocAmount", Number(sumallocAmount));
      // console.log("sumallocAmount", (sumallocAmount * 10**-18).toString());

      // // let r = await airdropContract.dropTokens(distribAddressData[i], distribAmountData[i]).send({ from: accounts[0], gas: gas, gasPrice: gPrice });
      const signer = provider.getSigner(accountAddress);

      //  // const tickeraddress = await pinftagramContract.tickerAddress();
      //  // console.log("tickeraddress", tickeraddress);

      const balance = await pinftagramContract.balanceOf(accountAddress);
      console.log("token contract balance", (balance * 10 ** -18).toString());


      // console.log(pinftagramContract.address)

      console.log(distribAmountData[0].toString());



      const txitem1 = await pinftagramContract.connect(signer).
        approve(pinftsContract.address, sumallocAmount.toString());

      const receipt1 = await txitem1.wait();
      if (receipt1.status === 0) {
        // alert.show("Approval failed. Please check your balance and try again");
        console.log("Transaction failed");
        throw new Error("Transaction failed");
      }


      for (let i = 0; i < distribAddressData.length; i++) {
        console.log(distribAddressData[i]);
        console.log(distribAmountData[i]);
        if (distribAddressData[i].length > 0) {
          await pinftsContract.connect(signer).multiTransfer(distribAddressData[i], distribAmountData[i])
        }

      }


    } catch (error) {
      console.log(error);
    }


    // csv.parseStream(stream)
    //   .on('error', error => {
    //     console.log(error)
    //   })
    //   .on('data', row => {
    //     console.log(row[0])
    //     // let isAddress = web3.utils.isAddress(row[0]);
    //     // if (isAddress && row[0] != null && row[0] != '') {

    //     //   allocAddressData.push(row[0]);
    //     //   allocAmountData.push(web3.utils.toWei( row[1]));

    //     //   index++;
    //     //   if (index >= BATCH_SIZE) {
    //     //     distribAddressData.push(allocAddressData);
    //     //     distribAmountData.push(allocAmountData);
    //     //     allocAmountData = [];
    //     //     allocAddressData = [];
    //     //     index = 0;
    //     //   }
    //     // }  
    //   })
    //   .on('end', rowCount => {
    //     //Add last remainder batch
    //     // distribAddressData.push(allocAddressData);
    //     // distribAmountData.push(allocAmountData);
    //     // console.log(distribAddressData);
    //     // console.log(distribAmountData);
    //     // allocAmountData = [];
    //     // allocAddressData = [];
    //     // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    //     //withdrawTokens();
    //     // airDrop();
    //   });

  }


  // ERC20 Token Airdrop
  const onSubmitAirdropPinftagram = async (e: any) => {
    e.preventDefault();
    // alert("ready to airdrop pinftagram");

    //     const checkowner = await this.state.whitetiggerfinanceContract.getOwner();
    //      console.log(checkowner);

    if (isAdmin) {
      getJsonDataERC20();
    } else {
      //  console.log("not the owner");
    }

  }

  // NFT Airdrop
  const onSubmitAirdropAutoBatch = async (e: any) => {
    e.preventDefault();
    // alert("ready to airdrop");
    if (isAdmin) {
      getJsonData();
    } else {
      //  console.log("not the owner");
    }
  }

  const updateMintingPrice = async (e: any) => {
    e.preventDefault();
    const rate = e.target.elements[0].value;
    if (rate === "") {
      return;
    }

    if (!(rate >= 0 && rate <= 100)) {
      // alert.show("Failed");
      return;
    }
    const rate2 = rate * 10 ** 2;
    // console.log(rate2);
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setMintingPrice(rate2.toString(), { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //  console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();

  }


  const updateBusdPinftRate = async (e: any) => {
    e.preventDefault();
    const rate = e.target.elements[0].value;
    if (rate === "") {
      return;
    }

    if (!(rate >= 0 && rate <= 1000)) {
      // alert.show("Failed");
      return;
    }
    const rate2 = rate * 10 ** 2;
    // console.log(rate2);
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setBusdPinftRate(rate2.toString(), { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //   console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();

  }

  const updatePinftRate = async (e: any) => {
    e.preventDefault();
    const rate = e.target.elements[0].value;
    console.log(rate)
    if (rate === "") {
      return;
    }

    if (!(rate >= 0 && rate <= 1000)) {
      // alert.show("Failed");
      return;
    }
    const rate2 = rate * 10 ** 1;
    console.log(rate2);
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setPinftTaxRate(rate2.toString(), { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //   console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();

  }
  const updateMintingReceiver = async (e: any) => {
    e.preventDefault();
    const addr = e.target.elements[0].value;
    if (addr === "") {
      return;
    }
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setMintingReceiver(addr, { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //   console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();
  }

  const updateBusdAddress = async (e: any) => {
    e.preventDefault();
    const addr = e.target.elements[0].value;
    if (addr === "") {
      return;
    }
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setBusdAddress(addr, { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //  console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();
  }

  const updateTreasuryAddress = async (e: any) => {
    e.preventDefault();
    const addr = e.target.elements[0].value;
    if (addr === "") {
      return;
    }
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setTreasuryAddress(addr, { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //   console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();
  }

  // const getallPinfts = async ( ) => {

  //   const nftcounter = await pinftsContract.pinftCounter();

  //   console.log(nftcounter.toString());

  //   const nftpinft1 = await pinftsContract.getPinftsPaging("0","200")
  //   console.log(nftpinft1.tokens );



  //   const pinfts = nftpinft1.tokens 
  //   console.log(pinfts)

  //   let stringoutput = ""
  //   pinfts.forEach((pinft1) => {

  //     const tokenid = pinft1.tokenid.toString();
  //     const name = pinft1.tokenname;
  //     const tags = pinft1.tags;
  //     const description = pinft1.description;
  //     const quantity = pinft1.quantity.toString();
  //     const tokenuri = pinft1.tokenuri;
  //     const price = pinft1.price.toString();
  //     const pinftorbusd = "0"

  //     stringoutput = `${stringoutput}\n${tokenid},${name},${tags},${description},${quantity},${tokenuri},${price},${pinftorbusd}`
  //   })

  //    console.log(stringoutput)
  //   // const txitem1 = await pinftsContract2.connect(signer1).mintPinft(name, tags, description, quantity.toString(), tokenURI, price.toString() ,pinftorbusd  , { from:  accountAddress })

  //   // const receipt1 = await txitem1.wait();  

  //   // if (receipt1.status === 0) {  
  //   //     await setLoading( false  );
  //   //     // alert.show("Minting failed");
  //   //     throw new Error("Transaction failed");
  //   // }


  //   // const nftpinft1 = await pinftsContract.mintPinft(string memory _name, string memory _tags, string memory _description, uint256 _amount, string memory _tokenURI, uint256 _price, uint8 _busdpinft
  //     }

  const updatePinftAddress = async (e: any) => {
    e.preventDefault();
    const addr = e.target.elements[0].value;
    if (addr === "") {
      return;
    }
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).setPinftAddress(addr, { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Failed");
        //  console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();
  }



  const addAdmin = async (e: any) => {
    e.preventDefault();
    const addr = e.target.elements[0].value;
    if (addr === "") {
      return;
    }
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).addAdmin(addr, { from: accountAddress });
      const receipt1 = await txitem1.wait();

      if (receipt1.status === 0) {
        // alert.show("Add admin failed");
        //   console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }
    setInProcessOfCalling(false);

    window.location.reload();
  }

  const removeAdmin = async (e: any) => {
    e.preventDefault();
    const addr = e.target.elements[0].value;

    if (addr === "") {
      return;
    }
    if (addr === accountAddress) {
      // alert.show("Cannot remove yourself as admin")
      return;
    }
    setInProcessOfCalling(true);
    try {
      const signer = provider.getSigner();
      const txitem1 = await pinftsContract.connect(signer).removeAdmin(addr, { from: accountAddress });

      const receipt1 = await txitem1.wait();
      setInProcessOfCalling(false);
      if (receipt1.status === 0) {
        // alert.show("Remove admin failed. Please check your balance and try again");
        //     console.log("Transaction failed");
        throw new Error("Transaction failed");
      }
    } catch (ex) {
      // alert.show(ex.data.message);
      // console.log(ex);
    }


    window.location.reload();
  }
  // {ethers.utils.formatEther(contractBalance)}
  // console.log(contractBalance)

  if (!pinftagramAddress || loading) {
    return (<>Loading</>)
  }

  return (
    <div>
      <div className="_2ndmainsection" style={{ margin: "auto", width: "500px" }}>
        <div >
          <div className="text-block-17 mint">Account Details{isAdmin && <> (admin)</>}

            <br />
            <span>{accountAddress}</span>
            <br />
            <ConnectToExplorer
              accountAddress={accountAddress}
              handle={myHandle.handlename}
            />
            <br /> <br />
            Balance : <br />

            <span ><NumberFormat value={accountBalance.toString()} displayType='text' thousandSeparator={show000} decimalScale={3} /> BNB </span >
            <br />
            <span><NumberFormat value={myPinftBalance.toString()} displayType='text' thousandSeparator={show000} decimalScale={3} />  PINFTAGRAM  </span >
            <br />
            <span><NumberFormat value={myBusdBalance.toString()} displayType='text' thousandSeparator={show000} decimalScale={3} />  BUSD  </span >

          </div>
        </div>

        <div className="mint-text-container">

          <div className="text-block-18">

            <div className="port-profile-image-holder">
              <Link to="/profile"     >
                <span className="ml-2 ">
                  <em className="">{myHandle.handlename}</em><br />
                  {myHandle.handlename === "" && <img alt="user" className="rounded " width="30" src={user7} style={{ paddingTop: "0px", marginTop: "0px" }} />}

                </span>

                &nbsp;&nbsp;
                <p>{myHandle.handletagline}</p>
              </Link>
            </div>


          </div>
          {isAdmin && <>

            <div style={{ margin: "auto", padding: "10px" }}>
              <div>

                {contractBalance > 0 &&
                  <>

                    <p>Withdraw from Contract  </p>
                    <form id="withdraw" onSubmit={e => withdrawfromContract(e)}>
                      {!inProcessOfCalling && (<button className="text-block-22" type="submit" >Withdraw   </button>)}
                    </form>

                  </>
                }
              </div>
            </div>
          </>

          }
          <br />
          <b>Busd Address </b>  {busdAddress} <br />

          <form id="busdaddress" onSubmit={e => updateBusdAddress(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="busdaddress" type="text" />
            <br /><br />

            {!inProcessOfCalling && (<button className="text-block-22" type="submit"  >Update  </button>)}

          </form><br /><br />
          <span><b>Busd Pinft Rate </b>  {busdPinftRate}</span>

          <form id="busdpinftrate" onSubmit={e => updateBusdPinftRate(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="busdpinftrate" type="text" />
            <br /><br />
            {!inProcessOfCalling && (<button className="text-block-22" type="submit" >Update </button>)}
          </form><br /><br />
          <span><b>Minting Receiver </b>  {mintingReceiver}  </span>
          <form id="mintingreceiver" onSubmit={e => updateMintingReceiver(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="mintingreceiver" type="text" />
            <br /><br />

            {!inProcessOfCalling && (<button className="text-block-22" type="submit"  >Update  </button>)}

          </form><br /><br />
          <br />
          <span><b>Minting Price (PINFT)</b>  {mintingPrice} </span>
          <form id="mintingprice" onSubmit={e => updateMintingPrice(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="mintingprice" type="text" />
            <br /><br />
            {!inProcessOfCalling && (<button className="text-block-22" type="submit" >Update </button>)}
          </form><br /><br />

          <br />
          <b>Treasury Receiver </b>  {treasuryReceiver}
          <form id="treasuryaddress" onSubmit={e => updateTreasuryAddress(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="treasuryaddress" type="text" />
            <br /><br />

            {!inProcessOfCalling && (<button className="text-block-22" type="submit"  >Update  </button>)}

          </form><br /><br />

          <br />
          <span><b>Tax Rate (0 to 100) </b>  {pinftTaxRate}</span>
          <form id="pinftrate" onSubmit={e => updatePinftRate(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="pinftrate" type="text" />
            <br /><br />
            {!inProcessOfCalling && (<button className="text-block-22" type="submit" >Update </button>)}
          </form><br /><br />

          <span><b>Pinft Address  </b> BNB{pinftBalance} </span>  {pinftagramAddress}

          <form id="pinftaddress" onSubmit={e => updatePinftAddress(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="pinftaddress" type="text" />
            <br /><br />

            {!inProcessOfCalling && (<button className="text-block-22" type="submit"  >Update  </button>)}

          </form><br /><br />


          <p>Enter new admin address  </p>
          <form id="addAdmin" onSubmit={e => addAdmin(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="adminAddrAdd" type="text" />
            <br /><br />
            {!inProcessOfCalling && (<button className="text-block-22" type="submit" >Create </button>)}
          </form>
          <br /><br />
          <p>Enter old admin address  </p>
          <form id="removeAdmin" onSubmit={e => removeAdmin(e)}>
            <input style={{ border: "1px solid", padding: "8px", height: "40px", width: "400px" }} id="adminAddrRem" type="text" />
            <br /><br />
            {!inProcessOfCalling && (<button className="text-block-22" type="submit" >Remove   </button>)}
          </form>
          <br /><br />
          <p>Airdrop NFT  </p>
          <br />
          <form id="airdropNFT" onSubmit={e => onSubmitAirdropAutoBatch(e)}>

            {!inProcessOfCalling && (<button
              className="text-block-22"
              type="submit" >Airdrop
            </button>)}
          </form>
          <br /><br />

          <p>Airdrop Pinftagram  </p>
          <br />

          <form id="airdropPinft" onSubmit={e => onSubmitAirdropPinftagram(e)}>

            {!inProcessOfCalling && (<button
              className="text-block-22"
              type="submit" >Airdrop
            </button>)}
          </form>

        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
