/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';

import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router';
import queryString from 'query-string';

import Moment from 'react-moment';


import { ConnectToExplorer, ConnectToGallery } from "../Common";
import SocialShare from "../SocialShare"


import NFTImage from "../NFTImage/NFTImage";

import Listings from "../Listings";
import Offers from "../Offers";
import config from "../../config.json";





const IndivToken = (props: any) => {


  // const [loading, setLoading] = useState(false);

  // const [loadingState, setLoadingState] = useState('not-loaded')
  const [loadedIndiv, setLoadedIndiv] = useState(false)
  // indiv entry
  // const [newPinftPrice, setNewPinftPrice] = useState("0");
  const [newListingPrice, setNewListingPrice] = useState("0");
  const [newOfferPrice, setNewOfferPrice] = useState("0");

  const [newDescription, setNewDescription] = useState('');
  const [newTags, setNewTags] = useState('');
  const [newTokenName, setNewTokenName] = useState('');

  const [newTokenAddress, setNewTokenAddress] = useState('');

  // const [selectedHandle, setSelectedHandle] = useState("");
  const [shareUrl, setShareUrl] = useState("");

  const [indivPinft, setIndivPinft] = useState<any>(null);
  const [seltokenid, setSeltokenid] = useState(1);


  const history = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();


  const getPinft = async (endpoint: any, token: string) => {
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  }

  const reportnft = (e: any) => {
    e.preventDefault();

    props.reportNFTDetails(indivPinft.tokenid);
  };

  const blocknft = (e: any) => {
    e.preventDefault();

    props.blockNFTDetails(indivPinft.tokenid, indivPinft.blocked, history);
  };

  const secretnft = (e: any) => {
    e.preventDefault();

    props.secretNFTDetails(indivPinft.tokenid, indivPinft.secret, history);
  };

  const updateNFTDetails = (e: any) => {
    e.preventDefault();

    props.changeTokenDetails(indivPinft.tokenid, indivPinft.price, newTokenName, newTags, newDescription);
  };

  const updateNFTAddress = (e: any) => {
    e.preventDefault();

    props.changeNFTAddress(indivPinft.tokenid, newTokenAddress);
  };


  const location = useLocation();

  const convertpinft = (urlin: string) => {
    return urlin.replace("https://ipfs.infura.io", "https://pinftagram.infura-ipfs.io");
  }

  useEffect(() => {
    async function checkPinfts() {
      setLoadedIndiv(false);

      // const seltoken = 1; // searchParams.get("tokenId")

      const queryurlparams = await queryString.parse(location.search);
      // console.log(queryurlparams)
      // console.log(queryurlparams.tokenId)
      const seltoken = Number(queryurlparams.tokenId);

      await setSeltokenid(seltoken);

      // console.log(seltoken)
      const pinftsIndiv = await getPinft(`${config.apiUrl}/pinftindiv?tokenid=${seltoken}`, props.accountAddress)
      // const pinftsIndiv = await props.pinftsContract.allPinfts(seltoken);
      if (pinftsIndiv === null) {
        // navigate(`/marketplace`); 
        return;
      }
      // console.log(pinftsIndiv)

      const tokenUri = convertpinft(pinftsIndiv.tokenuri);
      const meta = await axios.get(tokenUri);

      const totalmytokens = await props.pinftsContract.balanceOf(props.accountAddress, seltoken);


      let totalmintertokens = totalmytokens;
      if (pinftsIndiv.mintedby.toLowerCase() !== props.accountAddress.toLowerCase()) {
        totalmintertokens = await props.pinftsContract.balanceOf(pinftsIndiv.mintedby, seltoken);
      }

      const handlemintedby: any = props.handles.filter((item: { creator: string; }) => item.creator.toLowerCase() === pinftsIndiv.mintedby.toLowerCase());

      // let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
      const item = {
        price: pinftsIndiv.price,
        quantity: pinftsIndiv.quantity,
        tokenid: Number(seltoken),
        mytokens: totalmytokens,
        mintertokens: totalmintertokens,

        mintedby: pinftsIndiv.mintedby,
        numberoftransfers: pinftsIndiv.numberoftransfers,
        tokenname: pinftsIndiv.tokenname,
        image: meta.data.image,
        imagetype: meta.data.imagetype,
        image2: meta.data.animation_url,
        imagetype2: meta.data.animation_type,

        tags: pinftsIndiv.tags,
        description: pinftsIndiv.description,

        secret: pinftsIndiv.secret,
        blocked: pinftsIndiv.blocked,

        nfthandle: meta.data.nfthandle,
        posttime: pinftsIndiv.posttime,
        saletime: pinftsIndiv.saletime,
        handlemintedby: handlemintedby.length === 1 ?
          handlemintedby[0].handlename : ""
      }



      const shareurl = `${props.httpUrl}/indivtoken?tokenId=${seltoken}`;

      await setShareUrl(shareurl);

      await setIndivPinft(item);



      setNewTokenName(item.tokenname);
      setNewDescription(item.description);
      setNewTags(item.tags);
      const itemprice = Number(item.price) * 10 ** -18
      setNewListingPrice(itemprice.toString());


      await props.fetchListings("token", seltoken);
      await props.fetchOffers("token", seltoken);


      setLoadedIndiv(true);

    }

    checkPinfts();

    // const {myHandle} = props ;
    // const {handleimageuri, handlename, handletagline} =  myHandle;


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  if (!loadedIndiv) {
    return <div>.</div>
  }



  // console.log(props.listings)
  //   console.log(props.offers)


  // console.log(indivPinft)


  return (
    <div className="body-5">
      <div className="_2ndmainsection">

        <div className="mint-container">
          <div className="_2ndmainsection">
            <div className="listing-market-container">

              <div  >
                <NFTImage
                  pinft={indivPinft}

                  id={indivPinft.tokenid}
                  selectedItem={props.selectedItem}
                  selectedId={seltokenid}
                  selectIndividual={props.selectIndividual}
                  history={history}
                />

              </div>

              <div className="listing-text-main-container">

                <br />
                <div className="text-block-16">{indivPinft.tokenname}</div>
                <br />
                <SocialShare
                  title={indivPinft.tokenname.substr(0, 50)}
                  message={indivPinft.description.substr(0, 100)}
                  shareUrl={shareUrl}
                />
                <div className="listing-text-container-holder">
                  <div className="listing-title-container">
                    <div className="listing-text-block grey">
                      {indivPinft.description !== '' && <><br />Description</>}
                      {indivPinft.tags !== '' && <><br />Tags</>}
                      {indivPinft.price > 0 && <><br />Market Price </>}
                      <br />Minted Quantity
                      <br />Minted By
                      {indivPinft.mintertokens > 0 && <><br />Minter Owned</>}
                      {indivPinft.mytokens > 0 && <><br />Owned by Me</>}


                      <br />Minted
                      {indivPinft.saletime > 0 && <><br />Transacted</>}
                      {indivPinft.numberoftransfers.toString() !== "0" && <><br />No. of Transfers</>}
                      <br />Token ID


                    </div>
                    <div className="listing-text-block">

                      {indivPinft.description !== '' && <><br />{indivPinft.description}</>}
                      {indivPinft.tags !== '' && <><br />{indivPinft.tags}</>}
                      {indivPinft.price > 0 && <><br />{ethers.utils.formatEther(indivPinft.price.toString())} Pinft </>}
                      <br />{indivPinft.quantity.toString()}
                      <br />
                      <ConnectToExplorer
                        accountAddress={indivPinft.mintedby}
                        handle={indivPinft.handlemintedby}
                      />
                      <ConnectToGallery
                        accountAddress={indivPinft.mintedby}
                        addresstype="minted"
                        collection=""
                        handle={indivPinft.handlemintedby}
                      />

                      {indivPinft.mintertokens > 0 && <> <br /> {indivPinft.mintertokens.toString()}</>}
                      {indivPinft.mytokens > 0 && <><br />{indivPinft.mytokens.toString()} </>}

                      <br /><Moment fromNow>{parseInt(indivPinft.posttime) * 1000}</Moment>
                      {indivPinft.saletime > 0 && <><br /> <Moment fromNow>{parseInt(indivPinft.saletime) * 1000}</Moment></>}
                      {indivPinft.numberoftransfers.toString() !== "0" && <><br />{indivPinft.numberoftransfers.toString()}</>}


                      <br />{indivPinft.tokenid}
                      {indivPinft.secret && <><br />Secret</>}
                      {indivPinft.blocked && <><br />Blocked</>}
                    </div>
                  </div>





                  {indivPinft.mytokens > 0 && (props.myListings.length === 0) && (
                    <div className="listing-input-box-container">
                      <div className="listing-hr" />
                      <br />
                      <div className="text-block-16">Create Listing</div>
                      <div className="text-block-17">PINFT</div>
                      <div className="text-block-18" >
                        <input
                          autoComplete="off"
                          required
                          pattern="^\d*(\.\d{0,2})?$"
                          name="listingPrice"
                          id="listingPrice"
                          value={newListingPrice}
                          className="user-handle-input-box"
                          onChange={(e) => setNewListingPrice(e.target.value)}
                        />
                      </div>


                      <div className="listing-create-box">
                        <div  >
                          <button type="button" style={{
                            borderRadius: "50px", fontSize: "18px",
                            backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                          }}

                            onClick={() =>
                              props.createListing(
                                indivPinft.tokenid,
                                ethers.utils.parseEther(newListingPrice),
                                1,
                                history
                              )
                            }
                            disabled={!(newListingPrice !== "0")}   >
                            List
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {(props.myOffers.length === 0) && indivPinft.mytokens.toString() === "0" && (
                    <>
                      <div className="listing-input-box-container">
                        <div className="text-block-16">Create Offer</div>
                        <div className="text-block-17">PINFT</div>
                        <div className="text-block-18" >
                          <input
                            autoComplete="off"
                            required
                            pattern="^\d*(\.\d{0,2})?$"
                            name="offerPrice"
                            id="offerPrice"
                            value={newOfferPrice}
                            className="user-handle-input-box"
                            onChange={(e) => setNewOfferPrice(e.target.value)}
                          />
                        </div>

                        <div className="listing-create-box">
                          <div >
                            <button type="button" style={{
                              borderRadius: "50px", fontSize: "18px",
                              backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                            }}

                              onClick={() =>
                                props.createOffer(
                                  indivPinft.tokenid,
                                  ethers.utils.parseEther(newOfferPrice),
                                  1
                                )
                              }
                              disabled={!(newOfferPrice !== "0")}   >
                              Offer
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <Listings
                    listings={props.listings}
                    accountAddress={props.accountAddress}
                    createListing={props.createListing}
                    cancelListing={props.cancelListing}
                    acceptListing={props.acceptListing}
                    pinft={indivPinft}
                    history={history}
                    shareUrl={shareUrl}
                  />

                  <Offers
                    offers={props.offers}
                    accountAddress={props.accountAddress}
                    createOffer={props.createOffer}
                    cancelOffer={props.cancelOffer}
                    agreeOffer={props.agreeOffer}
                    acceptbuyoffer={props.acceptbuyoffer}
                    cancelagreeOffer={props.cancelagreeOffer}
                    pinft={indivPinft}
                    history={history}
                    shareUrl={shareUrl}
                  />



                  {!(window.location.href.includes('marketplace')) && indivPinft.mytokens > 0 && (props.listings.length === 0)
                    && (props.offers.length === 0)
                    && (
                      <>

                        <div className="listing-hr" />

                        <br />
                        <div className="text-block-16">Update Details</div>

                        <div className="listing-input-box-container">
                          <div className="text-block-17">Token Name</div>
                          <div className="text-block-18">
                            <input
                              className="user-handle-input-box"
                              name="newTokenName"
                              id="newTokenName"
                              defaultValue={indivPinft.tokenname}
                              placeholder="Enter new name"
                              onChange={(e) => setNewTokenName(e.target.value)}
                            />
                          </div >
                        </div>
                        <div className="listing-input-box-container">
                          <div className="text-block-17">Description</div>
                          <div className="text-block-18">
                            <textarea className="user-handle-input-box"

                              name="newDescription"
                              id="newDescription"
                              defaultValue={indivPinft.description}
                              placeholder="Enter new description"
                              onChange={(e) => setNewDescription(e.target.value)}
                            />
                          </div >
                        </div>

                        <div className="listing-input-box-container">
                          <div className="text-block-17">Tag</div>
                          <div className="text-block-18">
                            <textarea className="user-handle-input-box"
                              name="newTags"
                              id="newTags"
                              defaultValue={indivPinft.tags}
                              placeholder="Enter new tags"
                              onChange={(e) => setNewTags(e.target.value)}
                            />
                          </div >


                          <div className="listing-update-box">
                            <div  >
                              <button type="button" style={{
                                borderRadius: "50px", fontSize: "18px",
                                backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                              }}

                                onClick={(e) => updateNFTDetails(e)}
                                disabled={!(newTokenName !== '')}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                          <br /><br />
                        </div>
                      </>
                    )}
                  {/*        { !(window.location.href.includes('marketplace'))  && indivPinft.mytokens  > 0   &&  (props.listings.length === 0)  
              &&  (props.offers.length === 0)  
               && (
               <>

               <div className="listing-hr" />
              
              <br/>
               <div className="text-block-16">Transfer NFT Address</div>
 
            <div className="listing-input-box-container">
              <div className="text-block-17">New Address</div>
              <div  className="text-block-18">
                <input 
                 className="user-handle-input-box"
                  name="newAddress"
                  id="newAddress"
                  placeholder="Enter new address"
                  onChange={(e) =>  setNewTokenAddress( e.target.value) }
                />
              </div >
            </div>
            

              <div className="listing-update-box">
                <div  >
                   <button type="button" style={{borderRadius: "50px", fontSize: "18px", 
          backgroundColor: "black",color: "white", height: "35px",width: "135px", padding: "5px"}}     

                    onClick={(e) =>  updateNFTAddress(e)}
                    disabled={!(newTokenAddress !== '')} 
                     >
                       Update
                    </button>
                    </div>
              </div>
              <br/><br/>
             
               </>
               )} */}
                  {indivPinft.mytokens === 0 &&
                    <div className="listing-update-box">
                      <div  >
                        <button type="button"
                          className="listing-update-text"
                          onClick={(e) => reportnft(e)}

                        >
                          Report
                        </button>
                      </div>
                    </div>
                  }
                  {props.isAdmin &&
                    <div className="listing-update-box">
                      <div  >
                        <button type="button"
                          className="listing-update-text"
                          onClick={(e) => blocknft(e)}

                        >
                          {indivPinft.blocked ? "Unblock" : "Block"}
                        </button>
                      </div>
                    </div>
                  }
                  {indivPinft.mytokens > 0 &&
                    <div className="listing-update-box">
                      <div  >
                        <button type="button"
                          className="listing-update-text"
                          onClick={(e) => secretnft(e)}

                        >
                          {indivPinft.secret ? "List" : "Delist"}
                        </button>
                      </div>
                    </div>
                  }
                </div>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>


  )

};

export default IndivToken;


 // <img
 //                key={image}
 //                src={image}
 //                style={{width: "100%", display: "block"}}
 //                alt="dog"
 //              />
