/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Web3ReactProvider } from "@web3-react/core";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { BrowserRouter, Route, Routes, Navigate, useLocation, Link } from 'react-router-dom';
import connectors from "./connectors";
import App from './Application';
import "./index.css";
import reportWebVitals from './reportWebVitals';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Web3ReactProvider connectors={connectors}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </Web3ReactProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();




