/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ethers } from 'ethers';
import { useNavigate } from "react-router-dom";
import Moment from 'react-moment';
import axios from 'axios';
import ReactLoading from "react-loading";
import { ConnectToExplorer } from "../Common";


function Offers(props) {

  const history = useNavigate();


  const [offersProfile, setOffersProfile] = useState([]);

  const [loadingOffers, setLoadingOffers] = useState(true);



 
  async function fetchData() {
    if (window.location.href.includes('activity')) {
      const offers3 = await Promise.all(props.offers.map(async i => {

        // const selecteduri = await props.pinftsContract.uri(i.tokenid.toString())

        const selectedpinft = await props.pinftsContract.allPinfts(i.tokenid.toString())

        const meta = await axios.get(selectedpinft.tokenuri);

        const item = {
          price: selectedpinft.price,
          quantity: selectedpinft.quantity,
          tokenid: Number(selectedpinft.tokenid),
          mintedBy: selectedpinft.mintedBy,
          numberoforansfers: selectedpinft.numberoftransfers,
          tokenname: selectedpinft.tokenname,
          image: meta.data.image,
          imagetype: meta.data.imagetype,
          image2: meta.data.image2,
          imagetype2: meta.data.imagetype2,
          image3: meta.data.image3,
          imagetype3: meta.data.imagetype3,
          tags: selectedpinft.tags,
          description: selectedpinft.description,

          nfthandle: meta.data.nfthandle,
          posttime: selectedpinft.posttime,
          saletime: selectedpinft.saletime,

          id: i.id,
          unitprice: i.unitprice,

          buyeraddress: i.buyeraddress,
          selleraddress: i.selleraddress

        }

        return item
      }


      ))

      await setOffersProfile(offers3);

    } else {
      await setOffersProfile(props.offers);
    }
    setLoadingOffers(false)
  }


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.offers]);


  if (loadingOffers === true) return (
    <>
      <br />

      <ReactLoading type="balls" color="#000000"
        height={100} width={100} />
      <br />
    </>
  )

  return (
    <>
      {offersProfile.length > 0 &&
        <div style={{ padding: "8px" }}>

          <div className="text-block-16">

            {(window.location.href.includes('activity') ? "My Offers" : "Offers")}
          </div>
          <div  >


            {offersProfile.map((offeritem) => (
              <div key={offeritem.id}>
                {window.location.href.includes('activity') &&
                  <>

                    <img alt="imagetype" src={offeritem.image}
                      style={{ cursor: "pointer", display: "block", maxHeight: "600px" }}
                      aria-hidden="true"
                      onClick={() =>
                        props.fetchIndividual(
                          offeritem.tokenid.toString(),
                          history
                        )}
                    />
                    <br />

                    <br />{offeritem.tokenname}
                    <br />{offeritem.description}
                    <br />{offeritem.tags}
                    <br />
                  </>
                }

                Buyer :
                <ConnectToExplorer
                  accountAddress={offeritem.buyeraddress}
                  handle={offeritem.handlemintedby}
                />
                {offeritem.selleraddress.substring(0, 4) !== "0x00" &&
                  <>
                    <br />
                    Seller :
                    <ConnectToExplorer
                      accountAddress={offeritem.selleraddress}
                      handle=""
                    />
                  </>
                }
                <br /> <Moment fromNow>{parseInt(offeritem.posttime) * 1000}</Moment>

                <br />
                 {Number(offeritem.unitprice * 10 ** -18).toString()} PINFT
                  
           
                <br /><br />
                {props.accountAddress.toLowerCase() === offeritem.buyeraddress.toLowerCase() &&
                  <button type="button" style={{
                    borderRadius: "50px", fontSize: "18px",
                    backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                  }}

                    onClick={() =>
                      props.cancelOffer(
                        offeritem.id.toString(),
                        offeritem.tokenid.toString(),
                        offeritem.buyeraddress.toString()
                      )
                    }>
                    Cancel
                  </button>}
                {props.accountAddress.toLowerCase() !== offeritem.buyeraddress.toLowerCase() &&
                  props.pinft.mytokens > 0 && offeritem.selleraddress.substring(0, 4) === "0x00" &&
                  <button type="button" style={{
                    borderRadius: "50px", fontSize: "18px",
                    backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                  }}

                    onClick={() =>
                      props.agreeOffer(
                        offeritem.id.toString()
                      )}
                  >
                    Agree
                  </button>
                }
                {props.accountAddress.toLowerCase() !== offeritem.buyeraddress.toLowerCase() &&
                  props.pinft.mytokens > 0 &&
                  props.accountAddress.toLowerCase() === offeritem.selleraddress.toLowerCase() &&
                  <button type="button" style={{
                    borderRadius: "50px", fontSize: "18px",
                    backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                  }}

                    onClick={() =>
                      props.cancelagreeOffer(
                        offeritem.id.toString()
                      )}
                  >
                    Cancel Agree

                  </button>


                }
                {props.accountAddress.toLowerCase() === offeritem.buyeraddress.toLowerCase() &&
                  offeritem.selleraddress.substring(0, 4) !== "0x00" &&
                  <>
                    <br /> <br />
                    <button type="button" style={{
                      borderRadius: "50px", fontSize: "18px",
                      backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                    }}

                      onClick={() =>
                        props.acceptbuyoffer(
                          offeritem.id.toString(),
                          offeritem.tokenid.toString(),
                          offeritem.unitprice.toString(),
                          offeritem.quantity.toString(),
                          offeritem.buyeraddress.toString(),
                          offeritem.selleraddress.toString(),
                          false
                        )}
                    >
                      Buy with Pinft

                    </button>&nbsp;&nbsp;&nbsp;
                    <button type="button" style={{
                      borderRadius: "50px", fontSize: "18px",
                      backgroundColor: "black", color: "white", height: "35px", width: "135px", padding: "5px"
                    }}

                      onClick={() =>
                        props.acceptbuyoffer(
                          offeritem.id.toString(),
                          offeritem.tokenid.toString(),
                          offeritem.unitprice.toString(),
                          offeritem.quantity.toString(),
                          offeritem.buyeraddress.toString(),
                          offeritem.selleraddress.toString(),
                          true
                        )}
                    >
                      Buy with BUSD

                    </button>
                  </>

                }
              </div>

            ))}

          </div>
          <br />
          {/*
  <SocialShare
      title={props.pinft.tokenName.substr(0, 50)}
      message={props.pinft.description.substr(0, 100)}
      shareUrl= {props.shareUrl}
  /> 
  */}
          <br />
        </div>
      }
    </>
  )

}

export default Offers;