/* eslint-disable */
import React, { useState } from "react";

import ReactPlayer from "react-player/lazy";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Loading from "../Loading/Loading";

const NFTImage = (props) => {


  const [volmuted, setVolmuted] = useState(false);
  const [imgvolume, setImgvolume] = useState(0.6)
  const [videoplaying, setVideoplaying] = useState(false)
  // const [audioplaying, setAudioplaying] = useState(false)
  // const [audio, setAudio] = useState(props.pinft.image3)

  const [firstloaded, setFirstLoaded] = useState(true)


  const changemute = async (e) => {
    e.preventDefault();

    await setVolmuted(!volmuted);
    await setFirstLoaded(false);
  }

  const convertpinft = (urlin) => {
    return urlin.replace("https://ipfs.infura.io", "https://pinftagram.infura-ipfs.io");
  }

  const togglePlayer = async (e) => {
    e.preventDefault();
    await setFirstLoaded(false);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    if (videoplaying) {
      setVideoplaying(false)
    }
    else {
      setVideoplaying(true)
    }

  }
  // const toggleAudio = async (e) => {
  //   e.preventDefault();
  //   await setFirstLoaded(false);
  //   window.scrollTo({ behavior: 'smooth', top: '0px' });
  //   if (audioplaying) {
  //     setAudioplaying(false)
  //   }
  //   else {
  //     setAudioplaying(true)
  //     new Audio(audio).play();
  //   }

  // }  

// overflow-hidden

  return (

    <div key={props.i} className="rounded  ">
      <div style={{ backgroundColor: 'white' }}>
        {((props.pinft.imagetype2 === "video/mp4" || props.pinft.imagetype === "video/mpg" || props.pinft.imagetype === "video/quicktime")) &&
          <div key={props.i} style={{ width: "100%", height: "auto" }} >
            {firstloaded ?
              <div key={props.i} style={{ backgroundColor: "#eeeeee", padding: "5px" }}  >

                <img alt="imagetype" src={props.pinft.image}
                  style={{ cursor: "pointer", padding: "3px", display: "block", width: "100%", maxHeight: "600px" }}
                  onClick={() => props.selectIndividual(props.pinft, props.history)} aria-hidden="true" />

              </div>
              :
              <section onClick={(e) => togglePlayer(e)} aria-hidden="true" >

                <ReactPlayer
                  className="react-player"
                  url={convertpinft(props.pinft.image2)}
                  playsinline
                  playing={videoplaying}
                  loop={false}
                  width="100%"
                  onError={error => console.log(error)}
                  volume={imgvolume}
                  muted={volmuted}
                />
              </section>
            }
            <div style={{ textAlign: "center" }}>{!videoplaying && <h1 style={{ cursor: "pointer" }} onClick={(e) => togglePlayer(e)} aria-hidden="true">Click to Play Video</h1>}</div>
            {videoplaying && <button type="button" onClick={(e) => changemute(e)}>{!volmuted ? "Mute" : "Unmute"}</button>}



          </div>
        }
        {((props.pinft.imagetype2 !== "video/mp4" && props.pinft.imagetype2 !== "video/mpg" && props.pinft.imagetype2 !== "video/quicktime")) &&
          <div key={props.i} style={{ backgroundColor: "#eeeeee", paddingBottom: "2px" }}  >


            <LazyLoadImage
              alt={props.pinft.name}
              effect="blur"
              src={convertpinft(props.pinft.image)}
              style={{ cursor: "pointer", padding: "3px", display: "block", width: "476px", maxHeight: "600px" }}
              onClick={() => props.selectIndividual(props.pinft, props.history)} aria-hidden="true" />

          </div>


        }

      </div>

    </div>


  )
  // }
}


export default NFTImage;
